import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { PRODUCT_IMAGE_FOLDER, SESSION_APPLIED_REWARDS, SESSION_SALE_ITEM_LIST } from '../../app.constants';
import { SettingsService } from '../../services/shared/settings.service';
import { CommonModule } from '@angular/common';
import { StorageService } from '../../services/storage/storage.service';
import { CartItem } from '../../models/cart.model';
import { CartService } from '../../services/cart/cart.service';
import { TaxService } from '../../services/shared/tax.service';
import { MessageDialogService, NotificationDialogModel } from '../../services/shared/message-dialog.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../services/shared/notifications.service';
import { RewardSnackbarService } from '../../snackbar/reward-snackbar.service';
import { RewardService } from '../../services/shared/reward.service';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-promotion-item-dialog',
    imports: [CommonModule, FormsModule],
    templateUrl: './promotion-item-dialog.component.html',
    styleUrl: './promotion-item-dialog.component.scss'
})
export class PromotionItemDialogComponent {
  uploadUrl: any;
  imageUrl: any;
  systemSettings: any;
  currencySymbol: any;
  offer: any;
  // quantity: number = 1;
  // listItems :any=[];
  // allSaleItemList: any;
  // saleItemInfo: any;
  // offerItemInfo: any = [];
  // appliedRewards: any = [];
  // checked: any;
  // qty: any;
  // customizationDetail: any;
  // remark: any;
  showOffer: boolean = false;
  showBuy: boolean = false;
  promotionData: any;
  buyItem: any;
  offerItem: any;
  source: any;
  offerAmount: any;
  isCheckedOffer: boolean = false;
  isCheckedBuy: boolean=false;
  relatedBuyItem:any;
  relatedOfferItem:any;


  constructor(public dialogRef: MatDialogRef<PromotionItemDialogComponent>,
    private settingsService: SettingsService,
    private storageService: StorageService,
    private cartService: CartService,
    private taxService: TaxService,
    private messageDialogService: MessageDialogService,
    public dialog: MatDialog,
    private router: Router,
    private notificationService: NotificationService,
    private snackbarService: RewardSnackbarService,
    private rewardService: RewardService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.uploadUrl = environment.oos.assetsUrl;
    this.imageUrl = this.uploadUrl + PRODUCT_IMAGE_FOLDER;
    this.systemSettings = this.settingsService.systemSettings();
    this.currencySymbol = this.systemSettings.currency.symbol;
    this.offer = this.data.offer;
    this.promotionData = this.data.promoItemList;
    this.preSelectedPromotion();
    this.relatedBuyItem=this.buyItem;
    this.relatedOfferItem=this.offerItem;
    
    if (this.data.source.type == 'cart-item') {
      this.buyItem = undefined;
      this.promotionData.buy = undefined;
      this.relatedBuyItem=this.data.source.data;
    }
    this.showBuy = (this.promotionData.buy !== undefined && this.promotionData.buy.length > 0);
    this.showOffer = !this.showBuy;
  }

  ngOnInit() {
  }
/**
 * If only one promotion the item is automatically selected
 */
  preSelectedPromotion(){
    if (this.data.promoItemList.buy.length == 1) {
      this.isCheckedBuy = true;
      this.buyItem = this.rewardService.buildCartItemFromSaleItem(this.data.promoItemList.buy[0].item, this.data.promoItemList.buy[0].qty,this.offer.id,this.offer.customer_reward_id);
    }
    if (this.data.promoItemList.offer.length == 1) {
      this.isCheckedOffer = true;
      this.offerItem = this.rewardService.buildCartItemFromSaleItem(this.data.promoItemList.offer[0].item, this.data.promoItemList.offer[0].qty,this.offer.id,this.offer.customer_reward_id);
    }
  }

  getOfferAmountOfItem(promoItem: any) {
    const offer = this.offer.discount.offer.offer;
    const offerData = offer.filter((item: any) => {
      if (item.id == promoItem.item.saleItemId) {
        return item
      }
    });
    const discountAmount = (promoItem.item.fixedPrice * offerData[0].variance) / 100;
    this.offerAmount = promoItem.item.fixedPrice - discountAmount;
    return this.offerAmount;
  }
  /**
* Event to handle add button click.
* Emit the event back to parent
*/
  onAddBuyItem(item: any, qty: any): void {
    this.buyItem = this.rewardService.buildCartItemFromSaleItem(item, qty,this.offer.id,this.offer.customer_reward_id);
    this.relatedBuyItem=this.buyItem;
  }

  toggleUI() {
    this.showBuy = !this.showBuy;
    this.showOffer = !this.showOffer;

  }
  /**
   * When offer is selected
   * @param offerItemInfo 
   * @param qty 
   */
  onAddOfferItem(offerItemInfo: any, qty: any): void {
    this.offerItem = this.rewardService.buildCartItemFromSaleItem(offerItemInfo, qty,this.offer.id,this.offer.customer_reward_id);
    this.relatedOfferItem=this.offerItem;
  }

  /**
   * Add items to the
   */
  apply(): void {
    switch (this.offer.reward_type) {
      case 5:
        this.applyReward();
        this.cartService.addItem(this.offerItem);
        // this.snackbarService.showOfferAppled(this.offer);
        this.close();
        this.dialog.closeAll();
        break;
      case 4:

        if (this.buyItem) {
          this.cartService.addItem(this.buyItem);
        }
        if (this.offerItem) {
          this.cartService.addItem(this.offerItem);
        }
        // this.setRelatedItem(this.relatedBuyItem,this.relatedOfferItem);
        this.applyReward();
        this.notificationService.notifyCartChange("added")
        // this.snackbarService.showOfferAppled(this.offer);
        this.close();
        this.dialog.closeAll();

        break;
      default:
        break;
    }
  }

  /**
   * Sets the related item id for buy item and offer item
   * @param buyItem 
   * @param offerItem 
   */
  setRelatedItem(buyItem:any,offerItem:any):void{
    
    var buyItemCartId=(buyItem)?buyItem.itemId:'';
    var offerItemCartId=(offerItem)?offerItem.itemId:'';

    this.cartService.setRelatedItem(buyItemCartId,offerItemCartId);
  }

  /**
   * If offer is reward add to applied rewards
   */
  applyReward(): void {
    if (this.offer.reward_eligibility == 2) {

      this.rewardService.addToAppliedRewards(this.offer);
    }
  }



  close() {
    this.dialogRef.close();
  }
}
