import { Component } from '@angular/core';
import { StorageService } from '../../services/storage/storage.service';
import { SESSION_TOKEN } from '../../app.constants';
import { CustomerService } from '../../services/customer/customer.service';
import { CrmApiService } from '../../services/shared/crm-api.service';
import { Token } from '@angular/compiler';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-customer-activation',
    
    imports: [],
    templateUrl: './customer-activation.component.html',
    styleUrl: './customer-activation.component.scss'
})
export class CustomerActivationComponent {
  message: any;
  errorPage: any;
  companyInfo: any;
  token: any;
  


  constructor(
   
    private storageService: StorageService,
    private customerService: CustomerService,
    private crmApiService: CrmApiService,
    private route: ActivatedRoute,
    private router: Router
    ) {
  }
  ngOnInit(){
    // const token = JSON.parse(this.storageService.loadFromSession(SESSION_TOKEN));
    this.token = this.route.snapshot.paramMap.get('token');
    this.customerService.activateCustomer(this.token).subscribe(
      res => {
   this.message = res.message;

  }
);

  }

  OnHome(){
    this.router.navigate(['/']);
  }
 
}
