import { Component } from '@angular/core';
import { CartService } from '../../services/cart/cart.service';

import { StorageService } from "../../services/storage/storage.service";
;
import { CommonModule } from "@angular/common";
import { QRCodeComponent } from 'angularx-qrcode';

import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatDividerModule } from "@angular/material/divider";
import { MatButtonModule } from "@angular/material/button";
import {
  ReactiveFormsModule,
} from "@angular/forms";

import { MatSelectModule } from "@angular/material/select";
import { FormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { QrViewerComponent } from '../my-account/qr-viewer/qr-viewer.component';
import { Router, RouterModule } from '@angular/router';
import { NotificationService } from '../../services/shared/notifications.service';
import { Location } from '@angular/common'
import { LoginService } from '../login/login.service';

@Component({
    selector: 'app-account-option',
    
    imports: [CommonModule,
        QRCodeComponent,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        ReactiveFormsModule,
        MatSelectModule,
        FormsModule,
        MatInputModule,
        MatFormFieldModule, RouterModule],
    templateUrl: './account-option.component.html',
    styleUrl: './account-option.component.scss'
})
export class AccountOptionComponent {

  customer: any = {};
  custQrInfo: any;

  constructor(
    private location: Location,
    private notificationService: NotificationService,
    private storageService: StorageService,
    private cartService: CartService,
    private dialog: MatDialog,
    private router:Router,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    this.customer = this.cartService.getCustomer();
    // this.custQrInfo = JSON.stringify({
    //   customer_id: this.customer.id,
    //   customer_name: this.customer.firstName + " " + this.customer.lastName,
    // });
    this.custQrInfo = "Z"+ this.customer.id+"Z";

}

onShowQR() {
  const dialogConfig = new MatDialogConfig();
  dialogConfig.data = this.custQrInfo;
  dialogConfig.panelClass = "qr-code-viewer";
  const dialogRef = this.dialog.open(QrViewerComponent, dialogConfig);
}


MyAccount(){
  this.router.navigate(['/account']);
}
MYOrders(){
  this.router.navigate(['/orders']);
}

MyRwards(){
  this.router.navigate(['/my-rewards']);
}


logout() {
 this.loginService.logout();
  // this.dialog.closeAll();
}


back() {
  this.location.back()
 
}


}


