import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteConfirmation } from '../../../header/header.component';
import { environment } from '../../../../environments/environment';
import { SettingsService } from '../../../../services/shared/settings.service';
import { PRODUCT_IMAGE_FOLDER, SESSION_APPLIED_REWARDS, SESSION_SALE_ITEM_LIST } from '../../../../app.constants';
import { StorageService } from '../../../../services/storage/storage.service';
import { ItemListService } from '../../../../services/shared/item-list.service';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { CartService } from '../../../../services/cart/cart.service';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { OrderConfirmationService } from '../../../confirm-orders/order-confirmation.service';
import { NotificationService } from '../../../../services/shared/notifications.service';
import { MessageDialogService,NotificationDialogModel } from '../../../../services/shared/message-dialog.service';
import { RewardService } from '../../../../services/shared/reward.service';
import { GtmService } from '../../../../services/shared/gtm.service';

@Component({
    selector: 'app-cart-panel',
    imports: [CommonModule, RouterModule, MatSelectModule, FormsModule],
    templateUrl: './cart-panel.component.html',
    styleUrl: './cart-panel.component.scss'
})
export class CartPanelComponent {
  saleItemInfo: any;
  imageUrl: string;
  uploadUrl: any;
  systemSettings: any;
  allSaleItemList: any[];
  currencySymbol :any;
  selectedQuantity: any = 1;
  quantity: any=[];
  validItem: any;
  itemHasError: any;
  showCustomizationInCart: any;
  useToggleViewInCartCustomization=false;
  isShow: any;
  customizations:any;

  @Input() cartItem: any;
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();
  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  appliedRewards: any;
  removedOffer:any;
  pendingOfferDescription:any;
  isDisabled: boolean=false;

  constructor(public dialog: MatDialog,
    private settingsService:SettingsService,
    private storageService:StorageService,
    private itemListService:ItemListService,
    public cartService:CartService,
    private router:Router,
    private orderConfirmationService:OrderConfirmationService,
    private notificationService:NotificationService,
    private messageDialogService:MessageDialogService,
    private rewardService:RewardService,
    private gtmService:GtmService){
    this.uploadUrl = environment.oos.assetsUrl;
    this.imageUrl = this.uploadUrl + PRODUCT_IMAGE_FOLDER;

    this.systemSettings = this.settingsService.systemSettings;
    // this.showCustomizationInCart= (this.startUp.startupData.showCustomizationInCart && (this.cartItem.isCustomized || this.cartItem.remarks !== null));
    // this.useToggleViewInCartCustomization= this.startUp.startupData.useToggleViewInCartCustomization;
    // this.isShow=(this.showCustomizationInCart && !this.useToggleViewInCartCustomization);
    this.allSaleItemList = JSON.parse(this.storageService.loadFromSession(SESSION_SALE_ITEM_LIST));
    this.getSaleItem();
    this.getQuantity();

    if (this.systemSettings !== null) {
      // this.currencySymbol = this.systemSettings.currency.symbol;
      this.currencySymbol = '$';
    }  
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['cartItem']) {
      this.pendingOfferDescription=undefined;
      if(this.cartItem.offers.length<=0 && (this.cartItem.relatedItemId==undefined || this.cartItem.relatedItemId.length==0)){
        this.pendingOfferDescription=this.rewardService.getOfferPendingDescription(this.cartItem)
      }
    }
  }
   

  ngOnInit(){
    if (this.orderConfirmationService.errorJsonArrayOfCart !== null &&
      this.orderConfirmationService.errorJsonArrayOfCart !== undefined) {
      const error = this.orderConfirmationService.errorJsonArrayOfCart;

      this.validItem = error.filter((e:any) => e.type === 'item');
      if (this.validItem !== null && this.validItem !== undefined) {
        this.itemHasError = this.validItem.find((e:any) => e.id === this.cartItem.itemId);
      }
    }
    this.saleItemInfo = this.allSaleItemList.find(asl => asl.id === this.cartItem.ooSaleItemId);
    this.showCustomizationInCart= (environment.oos.showCustomizationInCart && (this.cartItem.isCustomized || (this.cartItem.remarks !== undefined && this.cartItem.remarks !== null && this.cartItem.remarks.toString().trim().length > 0)));
    this.useToggleViewInCartCustomization=true;
    this.isShow=(this.showCustomizationInCart );
    var options=this.cartService.getSelectedCustomizationOptionValueData(this.cartItem);
    this.customizations="";
    if (options) {
      for (var opt of options) {
        this.customizations += opt.nameToPrint + ", ";
      }
    }
    // if(this.cartItem.relatedItemId == null){
    //   this.pendingOfferDescription="";
    // }
    if((this.cartItem.offers!==undefined && this.cartItem.offers.length>0)){
      if(this.cartItem.offers[0].offer.discount.activation_mode == 0){
        this.isDisabled = true;
      }
      else{
        this.isDisabled = false;
      }
    }
  }

  getSaleItem() {
    this.allSaleItemList = JSON.parse(this.storageService.loadFromSession(SESSION_SALE_ITEM_LIST));
    if (this.allSaleItemList === undefined || this.allSaleItemList === null) {
      this.itemListService.loadSaleItem().subscribe(
        res => {
          this.allSaleItemList = res.object;
          this.storageService.saveToSession(SESSION_SALE_ITEM_LIST, this.allSaleItemList);
          this.saleItemInfo = this.allSaleItemList.find(asl => asl.id === this.cartItem.ooSaleItemId);
        });
    }
  }

  /**
   *
   * @param cartItem
   *  when clicking the delete button on the cart,
   * that item is deleted from the cart
   */
  deleteItemFromCart(cartItem:any) {
      this.delete.emit(this.cartItem); 
  }

  getQuantity() {
    this.quantity = [
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
      { label: '5', value: 5 },
      { label: '6', value: 6 },
      { label: '7', value: 7 },
      { label: '8', value: 8 },
      { label: '9', value: 9 },
      { label: '10', value: 10 },
    ];
  }

   /**
   * @param cartItem
   * when the item quantity is either increment or decrement,
   * it will reflect on the total price of the cart
   */
   getItemTotalPrice(cartItem:any) {
    const offerPrice=  [...this.cartItem.offers].reduce((sum, offer) => sum + offer.amount, 0);
    const customizationOfferPrice=cartItem.customizationOfferAmount;
    const total = this.cartItem.quantity * (this.cartItem.unitPriceWithTax);
    const custTotal= this.cartItem.quantity * (this.cartItem.customizationUnitPriceWithTax!==undefined?this.cartItem.customizationUnitPriceWithTax:0);
    cartItem.netCustomizationTotal=custTotal;
    cartItem.totalPrice = total;
    this.cartService.changeItem(cartItem);
    let totalOfferAmount:any;
    totalOfferAmount = Number((Math.round((offerPrice+customizationOfferPrice) * 100) / 100).toFixed(2));
    return ((cartItem.netTotal+cartItem.netCustomizationTotal)-totalOfferAmount);
  }

  /**
   * For take 2 Decimals.
   * @param str 
   * @param val 
   * @returns 
   */
  ParseFloat(str:any,val:any) {
    str = str.toString();
    // console.log(str.slice(0,str.indexOf(".")+val+1));
    if(str.indexOf(".")==-1){
      str=str+".00";
    }
    str = str.slice(0, (str.indexOf(".")) + val + 1); 
    return Number(str);   
}
/**
 * 
 * @param cartItem 
 * When redirect from cart the product will be updated accordingly.
 */
  editItemDetail(cartItem:any) {
    if(this.saleItemInfo !== null &&  this.saleItemInfo !== undefined && cartItem.hasError !== true){
      const path=(this.saleItemInfo.itemType==3)?'/comboDetail':'/itemDetail';
      this.router.navigate([path], { queryParams: { 'source': 'shoppingCart', 'id': cartItem.itemId  } });
    }
  }

  deleteConfirmation(){
    this.dialog.open(DeleteConfirmation,{
      panelClass:'confirm-dialog',
    })
  }

   /**
   * Remove the given reward from the array and save to session
   * @param reward 
   */
   removeReward(reward: any) {
    this.appliedRewards = JSON.parse(this.storageService.loadFromSession(SESSION_APPLIED_REWARDS));  
    this.removedOffer = this.appliedRewards.filter((rw: any) => rw.id !== reward[0].offerId);
    this.storageService.saveToSession(SESSION_APPLIED_REWARDS, JSON.stringify(this.removedOffer));
    this.notificationService.notifyRewardRemoved(this.removedOffer);
  }
  
  showError() {
    if(this.cartItem.errorMessage!==null) {
      var dlgData:NotificationDialogModel={
        message:this.cartItem.errorMessage,
        title:"Sorry",
        yesButtonCaption:"Ok",
      }
  
      var res=this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => {
        if(result=="Ok"){
  
          }
      });
    }
    else{
      var dlgData:NotificationDialogModel={
        message:`This item is temporarily unavailable. Please remove this item from your order to continue.`,
        title:"Sorry",
        yesButtonCaption:"Ok",
      }
  
      var res=this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => {
        if(result=="Ok"){
  
          }
      });
    }
   }
   /**
    * Set the quatity changes
    */
   itemQtyChanged():void{
    if(this.cartItem.itemType==3){
      this.cartService.setCombo(this.cartItem);
    }else{
     this.cartService.setCustomization(this.cartItem);
    }
    this.cartService.changeItem(this.cartItem);
    this.change.emit(this.cartItem);
   }

   showOffers(item:any){
    this.rewardService.checkForOffer(item);
   }
}
