import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule, NgFor } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/*******Services accociates with this common module*******/
import { SharedService } from '../services/shared/shared.service';
import { LoginService } from '../pages/login/login.service';
import { FcmService } from '../services/fcm/fcm.service';
import { HomeService } from '../pages/home/home.service';
import { CustomerService } from '../services/customer/customer.service';
import { CartService } from '../services/cart/cart.service';
import { StorageService } from '../services/storage/storage.service';
import { RewardService } from '../services/shared/reward.service';
import { CrmApiService } from '../services/shared/crm-api.service';
import { TokenService } from '../services/shared/token.service';
import { ShopService } from '../pages/recent-shops/shop.service';
import { TimeService } from '../services/shared/time-service.service';
import { DateTimeServiceService } from '../services/shared/date-time-service.service';
import { DatePipe } from '@angular/common';
import { ItemListService } from '../services/shared/item-list.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';


@NgModule({ declarations: [], imports: [CommonModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })], providers: [
        SharedService,
        LoginService,
        FcmService,
        HomeService,
        CustomerService,
        // StorageService,
        RewardService,
        CrmApiService,
        TokenService,
        ShopService,
        TimeService,
        DateTimeServiceService,
        DatePipe,
        ItemListService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class OOModule { 
}
