import { Component } from '@angular/core';
import { CommonModule, Location } from '@angular/common'
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from '../../services/storage/storage.service';
import { SESSION_AMENITY_LIST, SESSION_DEPARTMENT_LIST, SESSION_NEAREST_SHOP_LIST, SESSION_SELECTED_SHOP_LIST, SESSION_SHOP_LIST } from '../../app.constants';
import { OrderScheduleComponent } from '../order-schedule/order-schedule.component';

import { OOModule } from '../../modules/oo.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { UtilService } from '../../services/shared/util.service';
import { TimeService } from '../../services/shared/time-service.service';
import { ShopService } from '../recent-shops/shop.service';

@Component({
    selector: 'app-shop-detail',
    imports: [CommonModule, OOModule, GoogleMapsModule],
    providers: [],
    templateUrl: './shop-detail.component.html',
    styleUrl: './shop-detail.component.scss'
})
export class ShopDetailComponent {
  id: any;
  shop: any;
  allShopList: any;
  localserverTime: any;
  day: any;
  shoptime: any;
  departmentList: any;
  department: any;
  selectedShop: any;
  amenities: any;
  shopAmenityList: any;
  nearestShopList: any;
  shopList: any;
  hasNearestShop: boolean = false;
  msg: any;
  redirectUrl: any;
  shopStatus: any;
  shopOpenTimeToday: any;
  shopOpen: any;

  constructor(
    private location: Location,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private utilService: UtilService,
    private timeService:TimeService,
    private shopService:ShopService) {
  }

  ngOnInit() {
    this.utilService.scrollToTop();
    this.localserverTime = new Date();
    this.id = this.route.snapshot.paramMap.get('id');
    this.redirectUrl = this.route.snapshot.queryParams['redirectUrl'];
    this.allShopList = JSON.parse(this.storageService.loadFromSession(SESSION_SHOP_LIST));
    this.shop = this.allShopList.find((s: any) => s.id == this.id);
    // this.storageService.saveToSession(SESSION_SELECTED_SHOP_LIST,JSON.stringify(this.shop));
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    this.day = weekday[this.localserverTime.getDay()];
    const getColonTimeFromDate = (date: any) => date.toTimeString().slice(0, 8);
    this.shoptime = getColonTimeFromDate(new Date(this.localserverTime + 1000 * 60 * 30 * 7));

    this.allShopList = JSON.parse(this.storageService.loadFromSession(SESSION_SHOP_LIST));
    this.nearestShopList = JSON.parse(this.storageService.loadFromSession(SESSION_NEAREST_SHOP_LIST));
    this.departmentList = JSON.parse(this.storageService.loadFromSession(SESSION_DEPARTMENT_LIST));
    // if (this.nearestShopList == '') {
    this.shopList = this.allShopList;
    // }
    // else {
    //   this.hasNearestShop = true;
    //   this.shopList = this.allShopList.filter((shop: any) =>
    //     this.nearestShopList.some((nearestShop: any) => nearestShop.id === shop.id)
    //   );
    // }

    const mapOptions = {
      zoom: 10,
      center: { lat: this.shop.location.latitude, lng: this.shop.location.longitude }
    };

    // Create the map
    const map = new google.maps.Map(document.getElementById('map')!, mapOptions);

    // Add a marker
    const marker = new google.maps.Marker({
      position: { lat: this.shop.location.latitude, lng: this.shop.location.longitude },
      map: map,
      title: this.shop.name
    });
    marker.setMap(map);
    this.setAmenities();
  }

  setAmenities() {
    this.amenities = JSON.parse(this.storageService.loadFromSession(SESSION_AMENITY_LIST))
    if (this.amenities !== null && this.amenities !== undefined && this.shop.amenity !== null && this.shop.amenity !== undefined) {
    this.shopAmenityList = this.amenities.filter((amenity: any) =>
      this.shop.amenity.some((amenityShop: any) => amenityShop == amenity.id)
    );
    }
  }

  isBentoShop(shopId: Number): boolean {
    const shop = this.shopList.find((s: any) => s.id == shopId);
    const shopDepartmentList = shop.department;
    const bentoDpt = this.departmentList?.find((dpt: any) => dpt.code == 'BENTO');
    const shopBentodpt = shopDepartmentList?.find((dpt: any) => dpt == bentoDpt.id);
    return (shopBentodpt !== undefined && shopBentodpt !== null);
  }

  back() {
    this.location.back()
  }

  scheduleDialog(buzType: any, shopID: any) {
    const shop = this.shopList.find((s: any) => s.id == shopID);
    const dlgData = { businessType: buzType, shop: shop, redirectUrl: this.redirectUrl, msg: this.msg };
    this.dialog.open(OrderScheduleComponent, {
      panelClass: 'schedule-dialog',
      data: dlgData
    });
      
  //   if (this.redirectUrl == 'shoppingCart') {
  //     const shop = this.shopList.find((s: any) => s.id == shopID);
  //     const dlgData = { businessType: buzType, shop: shop, redirectUrl: 'shoppingCart', msg: this.msg }
  //     console.log(dlgData)
  //     this.dialog.open(OrderScheduleComponent, {
  //       panelClass: 'schedule-dialog',
  //       data: dlgData
  //     });
  //   }else{
  //       const shop = this.shopList.find((s: any) => s.id == shopID);
  //       const dlgData = { businessType: buzType, shop: shop, redirectUrl: 'shopDetail/:id', msg: this.msg }
  //       this.dialog.open(OrderScheduleComponent, {
  //         panelClass: 'schedule-dialog',
  //         data: dlgData
  //       });
  //     }
  }

  shopWorkingInfoChecking(shop:any) {
    const serverTime=new Date(this.timeService.serverTime);
    const shopWorkingInfo = this.shopService.getShopHours(shop, serverTime);
    this.shopStatus=shopWorkingInfo.shopStatus;
    this.shopOpenTimeToday = shopWorkingInfo.shopOpenToday;
    this.shopOpen=shopWorkingInfo.shopOpen;
    // return this.shopStatus;
  }
}
