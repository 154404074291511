import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
    selector: 'app-message-dialog',
    imports: [],
    templateUrl: './message-dialog.component.html',
    styleUrl: './message-dialog.component.scss'
})
export class MessageDialogComponent {
shop:any;
shopId:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private router:Router,
  public dialog: MatDialog){
    this.shop= this.data.shopName;
    this.shopId= this.data.shopId;
  }
  ngOnInit(){

  }

  continue(){
    this.router.navigate(['shopDetail/'+this.shopId],{ queryParams: {'redirectUrl': this.data.redirectUrl} });
    this.dialog.closeAll();
  }

  onShopSelection(){
    this.router.navigate(['/shopList'],{ queryParams: {'redirectUrl': this.data.redirectUrl} });
    this.dialog.closeAll();
  }
}
