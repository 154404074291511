import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-external-offer',
    
    imports: [],
    templateUrl: './external-offer.component.html',
    styleUrl: './external-offer.component.scss'
})
export class ExternalOfferComponent {
  promotion: any;
  pageContent: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ExternalOfferComponent>,
    private sanitizer: DomSanitizer,
  ) {
    this.promotion = this.data.externalPromotion;
    const content = this.data.offerContent.content;
    this.pageContent = this.sanitizer.bypassSecurityTrustHtml(content);
  }

  /**
   * For close the dialogue
   */
  close() {
    this.dialogRef.close()
  }
}
