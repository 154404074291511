import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { TokenService } from './token.service';
import { OOModule } from '../../modules/oo.module';

@Injectable({
  providedIn: 'root'
})
export class CrmApiService {

  token: any;
  crmBaseUrl: string

  constructor(private http:HttpClient,
    private tokenService:TokenService) {
    this.crmBaseUrl = environment.crm.url
    // this.getToken();
  }

  /**
   * Call server api to fetch the customer rewards
   * @param customerId 
   * @returns 
   */
  getRewards(customerId:any): Observable<any>{
    return this.tokenService.getToken().pipe( 
    map((response) => response), 
    catchError((error) => {
      throw error;
    }),
    switchMap((response: any) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: response,
        }),
      };
  
     return this.http.get(this.crmBaseUrl + '/customer/getRewards/' + customerId,httpOptions);
    })
    );

    
  }
}
