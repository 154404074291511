import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageService } from '../../services/storage/storage.service';
import { SESSION_SHOW_NOTICE } from '../../app.constants';

@Component({
    selector: 'app-shownotice',
    imports: [],
    templateUrl: './shownotice.component.html',
    styleUrl: './shownotice.component.scss'
})
export class ShownoticeComponent {
  // isInfoPageVisible = true;
  infoPageContent: any;
  @Input() companyInfo: any;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private sanitizer: DomSanitizer,
    private storageService: StorageService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['companyInfo']) {
      this.setInfoPage();
    }
  }
  
  /* set the info div contents */
  setInfoPage() {
    if (this.companyInfo !== null && this.companyInfo !== undefined) {
      if (this.companyInfo.infoPageContent !== null && this.companyInfo.infoPageContent !== undefined && this.companyInfo.infoPageContent.length !== 0) {
        let pageContent = this.companyInfo.infoPageContent;
        this.infoPageContent = this.sanitizer.bypassSecurityTrustHtml(pageContent);
      }
    }
  }


  hideOfferPanel() {
    this.storageService.saveToSession(SESSION_SHOW_NOTICE, false);
    this.close.emit();
  }

}
