import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RewardsComponent } from '../rewards/rewards.component'; import { LoginComponent } from '../login/login.component';
import { SignupComponent } from '../signup/signup.component';
import { DeleteConfirmation } from '../header/header.component';
import { OrderScheduleComponent } from '../order-schedule/order-schedule.component';
import { CommonModule, Location } from '@angular/common';
import { CartService } from '../../services/cart/cart.service';
import { StorageService } from '../../services/storage/storage.service';
import { SESSION_CART, SESSION_CONFIRMATION_MESSAGE, SESSION_TOKEN } from '../../app.constants';
import { OrderConfirmationService } from '../confirm-orders/order-confirmation.service';
import { Router } from '@angular/router';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { Cart } from '../../models/cart.model';
import { ItemListService } from '../../services/shared/item-list.service';
import { ConfirmationPanelComponent } from "../confirmation-panel/confirmation-panel.component";
import { SettingsService } from '../../services/shared/settings.service';
import { TimeService } from '../../services/shared/time-service.service';
import { UtilService } from '../../services/shared/util.service';

@Component({
    selector: 'app-order-confirm',
    imports: [CommonModule],
    templateUrl: './order-confirm.component.html',
    styleUrl: './order-confirm.component.scss'
})
export class OrderConfirmComponent {
  result: any;
  htmlData: SafeHtml | undefined;
  orderConfirmationDetails: any = [];
  shopTodayDetails: any = [];
  customerDetails: any = [];
  cartItemInfo: any = [];
  allSaleItemList: any = [];
  customerName: any;
  pickupDate: any;
  pickupTime: any;
  currencySymbol = "$";
  cart: Cart;
  confirmationService: any;
  rewardAmount: any;
  msg: any;
  offerAmount:any
  grandTotal: any;

  constructor(private location: Location, public dialog: MatDialog,
    public cartService: CartService,
    private storageService: StorageService,
    private orderConfirmationService: OrderConfirmationService,
    private router: Router, private sanitizer: DomSanitizer,
    private itemListService: ItemListService,
    private settingsService: SettingsService,
    private timeService: TimeService,
    private utilService: UtilService) {
    this.allSaleItemList = this.itemListService.saleItemList;
    this.cart = this.cartService.cart;
    this.cartItemInfo = this.cart.items
  }

  // Lifecycle hook
  ngOnInit() {
    this.utilService.scrollToTop();
    const customerCart=this.cartService.getCustomerCart();
    let cartTotal=this.cartService.getCartTotal();
    this.offerAmount=0;
    customerCart.items.forEach((item:any) => {
      this.offerAmount += item.totalOfferAmount;
    });
    // this.offerAmount = Number((Math.round(this.offerAmount  * 100) / 100).toFixed(2));
    this.grandTotal=cartTotal-this.offerAmount;
    
    // Handle empty cart scenario
    if (this.cartService.cart === undefined || this.cartService.cart.items?.length === 0) {
      // this.confirmationService.confirm({
      //   key: 'cd-fail',
      //   header: 'FAILED TO PROCESS ORDER',
      //   message: 'No Order details found. Please make order first.',
      //   accept: () => {
      //     this.router.navigate(['/order']);
      //   },
      // });
    } else { // Save cart and proceed
      this.cartService.saveCart().subscribe(
        (res: any) => {
          const result = res.success;
          if (result) {
            this.getOrderConfirmationHtml();
            this.storageService.saveToSession(SESSION_TOKEN, JSON.stringify(res.object.token));
          } else {
            this.orderConfirmationService.errorJsonArrayOfCart = res.object;
            this.msg = 'Operation failed', 'Failed to save order, please try again';
            // this.utilService.openDialog('Operation failed', 'Failed to save order, please try again');
            this.router.navigate(['/shoppingCart']);
          }
        }

      );
    }
  }

  // Fetch order confirmation HTML
  getOrderConfirmationHtml() {
    
    this.customerDetails=JSON.parse(this.storageService.loadFromSession(SESSION_CART));
    this.orderConfirmationDetails=this.cartService.getCartParam();
    // console.log(this.orderConfirmationDetails);
    this.rewardAmount=0
    const dd=this.orderConfirmationDetails.deliveryDate
    this.pickupDate=this.orderConfirmationDetails.deliveryDate.split("/").reverse().join("/");
    this.pickupTime=new Date('1970-01-01T' + this.orderConfirmationDetails.deliveryTime+ 'Z')
    .toLocaleTimeString('en-US',
      {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'}
    );
    const day=new Date(this.orderConfirmationDetails.deliveryDate).getDay();
    const delDay= (day==0)?7:day;
    this.shopTodayDetails=this.orderConfirmationDetails.shop.shopWorkingHours.find(
      (td:any)=>td.weekDay==delDay);
    this.orderConfirmationService.getOrderConfirmation().subscribe(
      (res: any) => {
        this.result = res.success;
        if (this.result) {
          this.htmlData = this.sanitizer.bypassSecurityTrustHtml(res.message);
          this.storageService.saveToSession(SESSION_CONFIRMATION_MESSAGE, res.message);

        }
      }
    );

  }

  // Proceed to payment
  // validate is token expired
  proceed() {
    if (!this.result) return;

    const token = JSON.parse(this.storageService.loadFromSession(SESSION_TOKEN));
    //     console.log(this.validateDeliveryTime())
    if (!this.validateDeliveryTime()) {
      this.openDialog();
      return;
    }
    //       this.router.navigate(['/shopItem'], { queryParams: { 'redirectUrl': '/Shoppingcart' } });
    //      return;
    //     }
    this.settingsService.validateToken(token).subscribe(
      res => {
        if (res.success) {
          // this.updateGTMInfoForCheckout();
          this.router.navigate(['/payment-init']);
        } else {
          this.msg = 'FAILED TO PROCESS ORDER ' + res.message
          // this.utilService.openDialog('FAILED TO PROCESS ORDER', res.message);
          if (res.errorCode === 'INVALID_PAYMENT_TOKEN') {
            this.router.navigate(['/shoppingCart']);
          }
        }
      }
    );
  }

  // Open dialog for schedule
  openDialog() {
    this.msg="";
    const dlgData = { businessType: this.orderConfirmationDetails.businessType.code, shop: this.orderConfirmationDetails.shop, redirectUrl: '/confirmationOrders', msg: this.msg }
    this.dialog.open(OrderScheduleComponent, {
      panelClass: 'schedule-dialog',
      data: dlgData
    });
  }

  // Validate delivery time
  validateDeliveryTime(): Boolean {

    let isValid = true;
    const dt = new Date(this.timeService.serverTime);
    const deliveryDate = this.cartService.cart.deliveryDate.date;
    const preparationTime = this.cartService.cart.businessType.initialDelay;
    const formttedDelivaryDate = new Date(this.cartService.cart.deliveryDate.date + ' ' + this.cartService.cart.deliveryTime);
    const orderBefore = new Date(this.cartService.cart.deliveryDate.date + ' ' + this.cartService.cart.deliveryTime);
    orderBefore.setMinutes(formttedDelivaryDate.getMinutes() - preparationTime);
    if (formttedDelivaryDate <= dt) {
      this.msg = 'The selected time has been expired.  We have set your earliest pickup time to '
      // this.utilService.openDialog('Time Expired.', 'The selected pickup time has expired. Please select another pickup time.');
      isValid = false;
    } else if (dt >= orderBefore) {
      this.msg = 'We are sorry. We need at least ' + preparationTime + ' minutes to prepare your order. We have set your earliest pickup time to '
      // this.utilService.openDialog('We are sorry.', 'We need at least ' + this.timeService.toHMFormat(preparationTime)
      // + ' to prepare your order. Please select another pickup time.');
      this.cartService.cart.deliveryTime = undefined;
      isValid = false;
    }
    return isValid;
  }

  // Handle delete item click event
  deleItemClickEvent(cartItem: any) {
    let saleItemData = this.allSaleItemList.find((asl: any) => asl.id === cartItem.ooSaleItemId);
    // this.updateGTMInfoForDelete('remove_from_cart', cartItem);
    this.cartService.deleteItem(cartItem);
    this.cartService.getValidPromotionalItemData();

    this.confirmationService.confirm({
      message: 'Do you want to delete ' + saleItemData.name + '?',
      accept: () => {
        // this.updateGTMInfoForDelete('remove_from_cart', cartItem);
        this.cartService.deleteItem(cartItem);
        this.cartService.getValidPromotionalItemData();
      },
      reject: () => {
        // cartItem.quantity = +cartItem.quantity;
      }
    });
  }

  getStatusBarButtonCaption(btnId: String):any{
    
    switch(btnId){
      case "pay":
       return "PAY $" + this.cartService.getCartTotal().toLocaleString('en-us', {minimumFractionDigits: 2});
    }
  }

  
  /**
 * Extract the selected customizations
 * @returns 
 */
  getSelectedCustomizations(cartItem:any): any {
    let selectedCustomization = [];
    let custTotalPrice = 0;
    for (let cd of cartItem.customization) {
      for (let ov of cd.optionValue) {
        if (ov.isSelected === 1 && ov.isDefault == 0) {
          selectedCustomization.push(ov);
          custTotalPrice += ov.fixedPrice;
        }
      }
    }
    return { selectedCustomization: selectedCustomization, total: custTotalPrice };

  }

   /**
 * For print 2 decimal point
 * @param str 
 * @param val 
 * @returns 
 */
ParseFloat(str:any,val:any) {
  str = str.toString();
    // console.log(str.slice(0,str.indexOf(".")+val+1));
    if(str.indexOf(".")==-1){
      str=str+".00";
    }
    str = str.slice(0, (str.indexOf(".")) + val + 1); 
    return Number(str);    
}

  updateGTMInfoForCheckout() {
    // const cartParam = this.cartService.getCartParam();


    // this.gtmService.pushEvent(
    //   '',
    //   {
    //     userId: this.gtmService.getCustomer(),
    //     visitorType: this.gtmService.getVisitorType(),
    //     dateSelectd: this.gtmService.geDeliveryDate(),
    //     timeSelected: this.gtmService.getDeliveryTime(),
    //     preOrder: this.gtmService.isPreOrder()

    //   });

    // let items = [];
    // for (const cartItem of cartParam.items) {
    //   let saleItemInfo = this.gtmService.getSaleItem(cartItem.ooSaleItemId);
    //   items.push({
    //     item_id: saleItemInfo.id,
    //     item_name: saleItemInfo.name,
    //     affiliation: this.gtmService.getStore(),
    //     item_brand: this.gtmService.getItemParentCategory(saleItemInfo).name,
    //     item_category: this.gtmService.getItemSubCategory(saleItemInfo).name,
    //     item_variant: '',
    //     price: cartItem.netTotal,
    //     quantity: cartItem.quantity

    //   });
    //   //{selectedCustomization:selectedCustomization,total:custTotalPrice};
    //   let customization = this.getSelectedCustomizations(cartItem);

    //   if (customization.selectedCustomization.length > 0) {
    //     items.push(
    //       {
    //         item_id: saleItemInfo.id,
    //         item_name: saleItemInfo.name,
    //         item_category2: (customization.selectedCustomization.length > 0) ? customization.selectedCustomization[0].nameToPrint : '',
    //         item_category3: (customization.selectedCustomization.length > 1) ? customization.selectedCustomization[1].nameToPrint : '',
    //         item_category4: (customization.selectedCustomization.length > 2) ? customization.selectedCustomization[2].nameToPrint : '',
    //         item_category5: (customization.selectedCustomization.length > 3) ? customization.selectedCustomization[3].nameToPrint : '',
    //         price: customization.total * cartItem.quantity,
    //         quantity: cartItem.quantity
    //       }
    //     );
    //   }
    // }

    // this.gtmService.pushG4AEvent(
    //   'add_payment_info',
    //   {
    //     ecommerce: {
    //       currency: 'NZD',
    //       value: this.cartService.getCartTotal(),
    //       items: items
    //     }
    //   });

  }
  // Navigate back
  back() {
    this.location.back()
  }
  
  // Open schedule dialog
  scheduleDialog() {
    this.dialog.open(OrderScheduleComponent, {
      panelClass: 'schedule-dialog'
    })
  }
}
