
import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { StorageService } from '../../../../services/storage/storage.service';
import { SESSION_FILTERED_SALE_ITEM_LIST, SESSION_SHOP_LIST } from '../../../../app.constants';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
    selector: 'app-serach-shop',
    imports: [CommonModule, FormsModule, MatAutocompleteModule, ReactiveFormsModule, RouterModule],
    templateUrl: './serach-shop.component.html',
    styleUrl: './serach-shop.component.scss',
    providers: [
        {
            provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
            useValue: { overlayPanelClass: 'shop-search-autocomplete' },
        },
    ]
})
export class SerachShopComponent {
  isOrderScheduleTrgd: boolean = false;
  searchControl = new FormControl('');
  shopList: any = [];
  filteredshopList: any = [];
  @Output() backClick: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('shop', { static: true }) itemInput!: ElementRef;

  constructor(private storageService: StorageService, private router: Router) {
    const shopList = JSON.parse(this.storageService.loadFromSession(SESSION_SHOP_LIST));
    this.shopList = shopList.sort(function (a: any, b: any) {
      if (a.name < b.name) return -1; // Sort ascending (a before b)
      if (a.name > b.name) return 1; // Sort ascending (b before a)
      return 0; // Equal names
    })
  }

  ngOnInit() {
    this.searchControl.valueChanges.subscribe(searchTerm => {
      this.filterShop();
    });
    this.setFocus();
  }

  /**
   *  Filter the itemList using JavaScript's filter() method
   */
  filterShop() {
    const searchTerm = this.searchControl.value?.toLocaleLowerCase();
    this.filteredshopList = this.shopList.filter((shop: any) => {
      return shop.name.toLowerCase().includes(searchTerm);
    });
  }

  // navigateToItemDetail(itemId: any) {

  //   this.router.navigate(['/shopDetail'], { queryParams: { 'source': '', 'id': itemId } });
  // }

  backButton() {
    this.backClick.emit();
  }

  navigateToShopDetail(shopId: any) {
    if (!this.isOrderScheduleTrgd) {
      this.router.navigate(['/shopDetail', shopId]);  // Use parameter binding
    }
    this.isOrderScheduleTrgd = false;
  }

  setFocus(){
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      document.getElementById("user-enter")?.focus();
    },1); 
  }
}


