@if (loginType==1) {
<div class="root-div">
    <button class="btn btn-close shadow-none opacity-100 position-absolute top-0 end-0 m-4" (click)="close()"></button>
    <div class="mb-4 pb-lg-2">
        <h3 class="font-secondary mb-1">Welcome Back!</h3>
        <p class="mb-0">Please log in to your account.</p>
    </div>
    <div>
        @if (sessionResponse && sessionResponseShowMessage) {
            <div class="alert alert-danger" role="alert">
                <span style="color: red;">{{sessionResponse}}</span>
            </div>
        }
        @else {
            @if(passwordResetShowMessage){
            @if (passwordResetStatus == 1) {
                @if(passwordResetResponse) {
                    <div class="alert alert-success" role="alert">
                        <span style="color: red;">{{passwordResetResponse}}</span>
                    </div>
                }
            }
            @if (passwordResetStatus == 0) {
                @if(passwordResetResponse) {
                    <div class="alert alert-success" role="alert">
                        <span style="color: red;">{{passwordResetResponse}}</span>
                        &nbsp;<span class="spinner-border text-dark" style="height: 16px;width: 16px;"></span>
                    </div>
                }
            }
        }
        }
    </div>
    <form [formGroup]="loginForm" autocomplete="on" (ngSubmit)="onSubmit(loginForm.value)">
        <div class="mb-2 pb-1 pb-lg-0 mb-lg-3">
            <label for="loginId" class="form-label mb-0">Email*</label>
            <input type="email" class="form-control shadow-none rounded-1 theme-padding" formControlName="customerName" style="font-family: var(--futura-bold)"
                name="customerName" id="customerName" required>
            <div class="text-danger">
                @if (submitted && formControl.customerName.errors) {
                @if (formControl.customerName.errors.email) {
                <div>Email must be valid.</div>
                }
                @if (formControl.customerName.errors.required) {
                <div>Email is required.</div>
                }
                }
                @if (forgoterror) {
                    <div>{{forgoterror}}</div>
                }
            </div>
        </div>
        <div class="mb-3 w-100">
            <label for="password" class="form-label mb-0">Password</label>
            <div class="position-relative">
                <input class="form-control shadow-none rounded-1 pe-4 theme-padding"
                    [type]="pwHide ? 'password' : 'text'" style="margin-top:10px" formControlName="password"
                    name="password" id="password" required>
                <img class="position-absolute end-0 my-auto me-2 bottom-0 top-0" src="assets/icons/eye.svg"
                    *ngIf="pwHide" alt="" role="button" (click)="pwHide = !pwHide">
                <img class="position-absolute end-0 my-auto me-2 bottom-0 top-0" src="assets/icons/eye-slash.svg"
                    *ngIf="!pwHide" alt="" role="button" (click)="pwHide = !pwHide">
                <div class="text-danger">
                    @if (submitted && formControl.password.errors) {
                    @if (formControl.password.errors.required) {
                    <div>Password is required.</div>
                    }
                    }
                </div>
            </div>
        </div>
        <div class="form-check mb-3">
            <input role="button" class="form-check-input shadow-none" type="checkbox" value="" id="flexCheckDefault" [checked]="isRemembered"
            (change)="rememberMeCheck()">
            <label role="button" class="form-check-label" for="flexCheckDefault" style="font-family: var(--futura-bold)">
                Remember Me
            </label>
            @if (passwordResetStatus==1) {
            <div class="mb-0 d-inline-block float-end ps-2" role="button" (click)="forgotPasswordClick()" style="font-family: var(--futura-bold);font-weight: bold;">Forgot Password?</div>
            }
        </div>
        @if (googleResponse==false) {
           <span class="btn py-2 px-4 w-100 mb-4 fs-5">Signing in with <img src="assets/icons/google-Icon.svg"
                class="align-middle mb-1" alt="" style="height: 20px;">oogle<img src="assets/icons/loading_gif.gif" style="margin-left: 2px;" />
            </span>
        }
        @else {
        <button class="btn btn-primary py-2 px-4 w-100 rounded-pill font-secondary mb-4" type="submit">Log In</button>
        }
    </form>



    <div class="mb-3 d-flex">
        <hr class="col my-auto">
        <p class="mx-4 mb-0 col-auto">Or</p>
        <hr class="col my-auto">
    </div>

    <div class="d-flex mb-4 pb-2">
        <button class="btn btn-outline-dark py-2 col rounded-1" (click)="googlelogin()"><img src="assets/icons/google-Icon.svg"
                class="align-middle me-2" alt=""><span class="align-middle">&nbsp;Google</span></button>
        <button class="btn btn-outline-dark py-2 col rounded-1 ms-3" (click)="facebooklogin()"><img
                src="assets/icons/facebook-Icon.svg" class="align-middle me-2" alt=""><span
                class="align-middle">&nbsp;Facebook</span></button>
    </div>

    <p class="mb-0">Don’t have an account? <span class="fw-bold" role="button" (click)="signupDialog()">Sign Up&nbsp;
            <img src="assets/icons/back_arrow_icon.svg" class="align-top" style="transform: rotate(180deg);" /></span>
    </p>
</div>
}

@if(loginType==2){
<div class="root-div">
    <div style="text-align: center;height: 200px;padding-top: 60px;">
        <img src="assets/images/logos/mail_logo.png" class="img-fluid" alt="logo" style="width: 100px;">
    </div>
    <div style="height: 250px;">
           <span class="btn py-2 px-4 w-100 mb-4">Signing in with 
                <img src="assets/icons/google.svg"
                    class="align-middle" style="width: 70px;">
                <img src="assets/icons/loading-google.gif" style="margin-left: 2px;height: 25px;" />
            </span>

    </div>
</div>
}

@if(loginType==3){
    <div class="root-div">
        <div style="text-align: center;height: 200px;padding-top: 60px;">
            <img src="assets/images/logos/mail_logo.png" class="img-fluid" alt="logo" style="width: 100px;">
        </div>
        <div style="height: 250px;">
               <span class="btn py-2 px-4 w-100 mb-4">Signing in with 
                    <!-- <img src="assets/icons/facebook-Icon-r.svg"class="align-middle" alt=""> -->
                    <span style="color: #3578E5;">facebook</span>
                    <img src="assets/icons/loading-facebook.gif" style="margin-left: 2px;height: 25px;" />
                </span>
        </div>
    </div>
    }