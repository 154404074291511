import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { SignupComponent } from "../../../../signup/signup.component";
import { LoginComponent } from "../../../../login/login.component";

@Component({
    selector: 'app-offer-message-dialog',
    imports: [],
    templateUrl: './offer-message-dialog.component.html',
    styleUrl: './offer-message-dialog.component.scss'
})
export class OfferMessageDialogComponent {

  title: string;
  message: string;
  showNoButton:boolean=true;
  noButtonCaption: string='No';
  yesButtonCaption: string='Yes';
  signINButtonCaption: string='Yes';

  constructor(public dialogRef: MatDialogRef<OfferMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog,) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.showNoButton= (data.showNoButton !==  undefined && data.showNoButton == false ) ? false : true;
    this.noButtonCaption=(data.noButtonCaption !==  undefined) ? data.noButtonCaption : 'No';
    this.yesButtonCaption=(data.yesButtonCaption !==  undefined ) ? data.yesButtonCaption : 'Yes';
    this.signINButtonCaption=(data.signINButtonCaption!==  undefined ) ? data.signINButtonCaption : 'Yes';
  }

  ngOnInit() {
  }

  /**
   * Close the dialog, return true
   */
  onConfirm(): void {
    this.dialogRef.close(this.yesButtonCaption);
  }

  /**
   * Close the dialog, return false
   */
  onDismiss(): void {
    this.dialogRef.close(this.noButtonCaption);
  }

  onSignUp(){
      this.dialog.open(SignupComponent, {
        panelClass: 'signup-dialog'
      })
      this.close()
  }

  onSignIn(){
    this.dialog.open(LoginComponent,{
      panelClass: 'login-dialog'
    })
    this.close();
  }

  close() {
    this.dialogRef.close()
  }
}



