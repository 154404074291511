import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SignupComponent } from '../signup/signup.component';
import { OOModule } from '../../modules/oo.module';
import { ReactiveFormsModule, FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from './login.service';
import { CartService } from '../../services/cart/cart.service'

import { getAuth, signInWithPopup, FacebookAuthProvider, GoogleAuthProvider } from "firebase/auth";
import { environment } from '../../environments/environment';
import { initializeApp } from 'firebase/app';
import { CommonModule } from '@angular/common';
import { RewardService } from '../../services/shared/reward.service';
import { NotificationService } from '../../services/shared/notifications.service';
import { StorageService } from '../../services/storage/storage.service';
import { SESSION_AUTHENTICATED_CUSTOMER } from '../../app.constants';
import { GtmService } from '../../services/shared/gtm.service';
import { map } from 'rxjs';
initializeApp(environment.firebase.config);


@Component({
    selector: 'app-login',
    imports: [OOModule, ReactiveFormsModule, CommonModule],
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss'
})
export class LoginComponent {
  registrationStatus: number = 0;
  loginForm: any
  customerName: any;
  password: any;
  pwHide = true;
  public submitted = false;
  sessionResponse = "";
  passwordResetResponse = "";
  isRemembered: Boolean = false;
  forgoterror: any;
  credentialgoogle: any;
  credentialfacebook: any;
  passwordResetStatus: number=1;
  passwordResetShowMessage: boolean=false;
  sessionResponseShowMessage: any;
  loginType : number=1;
  googleResponse: boolean = true;

  constructor(public dialogRef: MatDialogRef<LoginComponent>,
    public dialog: MatDialog,
    public route: Router,
    public loginService: LoginService,
    private cartService: CartService,
    private rewardService: RewardService,
    private notificationService: NotificationService,
    private storageService: StorageService,
    private router: Router,
    private gtmService: GtmService
  ) { }

  ngOnInit() {
    /* Defined Reactive form for login*/
    this.loginForm = new FormGroup({
      customerName: new FormControl("", [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl("", [
        Validators.required
      ])
    })
  }

  close() {
    this.dialogRef.close()
  }

  //SignUp dialogbox opened
  signupDialog() {
    // this.router.navigate(['/registraction']);
    this.dialog.open(SignupComponent, {
      panelClass: 'signup-dialog'
    })
    this.close()
  }

  //Forgotpassword validated email
  forgotPasswordClick() {
    if (this.formControl.customerName.errors == null) {
      this.forgoterror = ""
      this.forgotDialog();
    }
    else {
      this.forgoterror = "Email address can not be blank. Please enter your email."
    }
  }

  //If the confirmation of forgot password is yes then reset the password
  forgotDialog() {
    const dialogRef = this.dialog.open(ForgotComponent, {
      panelClass: 'confirm-dialog'
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data == true) {
        this.passwordReset();
      }
    })
  }

  get formControl() {
    return this.loginForm.controls;
  }

  rememberMeCheck() {
    this.isRemembered = !this.isRemembered;
  }

  //Password reset request pass to API and get response
  passwordReset() {
    this.passwordResetShowMessage=true;
    this.sessionResponseShowMessage=false;
    const credential = { 'customerName': this.formControl.customerName.value };
    this.passwordResetStatus = 0;
    this.passwordResetResponse = "Requesting for new password."
    this.loginService.forgotPassword(credential).subscribe(
      (res) => {
        if (!res) {
          this.passwordResetStatus = 0;
          this.passwordResetResponse = "Requesting for new password."
        }
        else {
          this.passwordResetStatus = 1;
          this.passwordResetResponse = res.message
        }
      }
    );
  }

  /** User authentication using entered username and password*/
  onSubmit(data: any) {
    this.submitted = true;
    if (this.formControl.customerName.errors == null && this.formControl.password.errors == null) {
      const credential = { 'customerName': data.customerName, 'password': data.password };
      this.loginService.authenticateCustomer(credential).subscribe((data: any) => {
        if (data.success == false) {
          this.sessionResponseShowMessage=true;
          this.sessionResponse = data.message;
          this.passwordResetShowMessage=false;
        }
        else {
          this.cartService.setCustomer(data.object.customer);
          this.storageService.saveToSession(SESSION_AUTHENTICATED_CUSTOMER, JSON.stringify(data.object.customer));
          /**
         * GTM Code Start
         */
          this.gtmService.pushEvent(
            'login',
            {
              userId: data.object.customer.id,
              authentication_method: 'account',
              visitorType: this.gtmService.getVisitorType()
            });
          /**
        * GTM Code End
        */
          this.notificationService.notifyLoginChange('in');
          this.rewardService.loadCustomerRewards(data.object.customer.id).subscribe((response) => {
            if (response.status == 'SUCCESS') {
              const customerRewards = response.data.customer_reward;
              if (customerRewards != null) {
                // this.notificationService.notifyRewardsLoaded(customerRewards);
                this.rewardService.autoloadReward();
              }
            }
          });
          this.sessionResponse = "";
          if (this.isRemembered == true) {
            this.loginService.rememberCustomer(data.object.customer.id)
          }
          this.close();
        }
      })
    }
  }

  /** User authentication using facebook login with firebase*/
  auth = getAuth();
  facebooklogin() {
    this.loginType=3;
    const provider = new FacebookAuthProvider();
    // provider.addScope('email'); 
    provider.setCustomParameters({
      'display': 'popup'
    })
    signInWithPopup(this.auth, provider)
      .then((result) => {
        const user = result.user;
        // console.log(result);
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential?.accessToken
        if (user.email !== null) {
          this.credentialfacebook = {
            "customerType": 0,
            "loginId": user.email,
            "email": user.email,
            "name": user.displayName,
            "facebookAccessToken": accessToken
          }
        } else {
          this.credentialfacebook = {
            "customerType": 0,
            "loginId": user.providerData[0].email,
            "email": user.providerData[0].email,
            "name": user.providerData[0].displayName,
            "facebookAccessToken": accessToken
          }
        }
        if (!accessToken) {
          this.sessionResponseShowMessage=true;
          this.sessionResponse = "Not a Valid User";
          this.passwordResetShowMessage=false;
        }
        else {
          this.loginService.authenticatesocialCustomer(this.credentialfacebook).subscribe((data: any) => {
            if (data.success == false) {
              this.sessionResponseShowMessage=true;
              this.sessionResponse = data.message
              this.passwordResetShowMessage=false;
            }
            else {
              this.cartService.setCustomer(data.object)
              this.storageService.saveToSession(SESSION_AUTHENTICATED_CUSTOMER, JSON.stringify(data.object.customer));
              this.sessionResponse = "";
              this.close();
              /**
          * GTM Code start
          */
              this.gtmService.pushEvent(
                'login',
                {
                  userId: this.gtmService.getCustomer(),
                  authentication_method: "facebook login",
                  visitorType: this.gtmService.getVisitorType()
                });
              /**
            * GTM Code end
            */
              this.notificationService.notifyLoginChange('in');
            }

          })
        }
      })
      .catch((error) => {
        this.loginType=1;
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);
        console.log("error is : " + error)

      })
  }

  /** User authentication using google login with firebase*/
  googlelogin() {
    this.loginType=2;
    const provider = new GoogleAuthProvider();
    provider.addScope('email');
    provider.setCustomParameters({
      'login_hint': 'user@example.com'
    });

    signInWithPopup(this.auth, provider)
      .then((result) => {
        var user = result.user;
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const accessToken = credential?.accessToken
        if (user.email !== null) {
          this.credentialgoogle = {
            "customerType": 0,
            "loginId": user.email,
            "email": user.email,
            "name": user.displayName,
            "facebookAccessToken": accessToken
          }
        } else {
          this.credentialgoogle = {
            "customerType": 0,
            "loginId": user.providerData[0].email,
            "email": user.providerData[0].email,
            "name": user.providerData[0].displayName,
            "facebookAccessToken": accessToken
          }
        }
        if (!accessToken) {
          this.sessionResponseShowMessage=true;
          this.sessionResponse = "Not a Valid User";
          this.passwordResetShowMessage=false;
        }
        else {
          this.loginService.authenticatesocialCustomer(this.credentialgoogle).subscribe((data: any) => {
            if (data.success == false) {
              this.sessionResponseShowMessage=true;
              this.sessionResponse = data.message
              this.passwordResetShowMessage=false;
              this.googleResponse = false;
            }
            else {
              this.googleResponse = true;
              this.cartService.setCustomer(data.object)
              this.storageService.saveToSession(SESSION_AUTHENTICATED_CUSTOMER, JSON.stringify(data.object.customer));
              this.sessionResponse = "";
              this.close();
              /* GTM Code start
              */
              this.gtmService.pushEvent(
                'login',
                {
                  userId: this.gtmService.getCustomer(),
                  authentication_method: "google login",
                  visitorType: this.gtmService.getVisitorType()
                });
              /**
            * GTM Code end
            */
              this.notificationService.notifyLoginChange('in');
            }
          })
        }
      })
      .catch((error) => {
        this.loginType=1;
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log("error is : " + error)

      })
  }

  clearFunction() { }
}

@Component({
  selector: 'app-forgot',
  template: `
  <section>
    <h5 class="font-secondary mb-4">Are You Sure?</h5>
    <p>This will reset your password and a new password will be sent to your mail?</p>
    <div class="text-end pt-1 font-secondary">
      <button class="btn btn-outline-dark py-2 px-4 rounded-pill me-2" (click)="loginDialog()">No</button>
      <button class="btn btn-primary py-2 px-4 rounded-pill" (click)="passwordReset()">Yes</button>
    </div>
  </section>`,
  standalone: true,
})

export class ForgotComponent {
  constructor(public dialogRef: MatDialogRef<ForgotComponent>, public dialog: MatDialog) { }

  passwordReset() {
    this.dialogRef.close(true);
  }

  //Login dialog box is opened
  loginDialog() {
    // this.dialog.open(LoginComponent, {
    //   panelClass: 'login-dialog'
    // })
    this.dialogRef.close(false);
  }
}
