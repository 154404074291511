import { Component, Input } from '@angular/core';
import { Location } from '@angular/common'
import { CustomerService } from "../../services/customer/customer.service";
import { response } from 'express';
import { error } from 'console';
import { SESSION_AUTHENTICATED_CUSTOMER, SESSION_CART, SESSION_SYSTEM_SETTINGS } from '../../app.constants';
import { StorageService } from '../../services/storage/storage.service';
import { environment } from '../../environments/environment';
import moment from 'moment';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { UtilService } from '../../services/shared/util.service';


@Component({
    selector: 'app-my-orders',
    imports: [CommonModule, RouterModule],
    templateUrl: './my-orders.component.html',
    styleUrl: './my-orders.component.scss'
})
export class MyOrdersComponent {
  itemPerPage: number;
  listCount: number;
  offset: number
  sessionCustomer: any;
  customerId: any;
  result: any;
  orderHistory: any;
  @Input() items: any;
  itemCount: any;
  orderHistoryArray: any[];
  imageUrl:string='';
  currencySymbol: any;
  systemSettings: any;
 
  
 
 
  constructor(private location: Location,private customerservice:CustomerService,
    private storageService: StorageService,private router:Router,
    private utilService:UtilService) {
    this.customerId = JSON.parse(this.storageService.loadFromSession(SESSION_CART)).customer.id
    this.itemPerPage = 10;
    this.listCount = 1;
    this.offset = (this.listCount - 1) * this.itemPerPage;
    this.orderHistoryArray = [];
    this.getOrderHistory();
    this.imageUrl=environment.oos.assetsUrl;
    this.systemSettings = JSON.parse(this.storageService.loadFromSession(SESSION_SYSTEM_SETTINGS));
    this.currencySymbol = this.systemSettings.currency.symbol;
   
  }

  back() {
    this.location.back()
   
  }
  ngOnInit() {
    this.utilService.scrollToTop();
  }

  /**
   * to load order details 
   */
  getOrderHistory():void{
    this.customerservice.getMyOrders( this.customerId,this.itemPerPage, this.offset).subscribe({
      next:(response)=>{
        this.result = response.success;
      if (this.result) {
        this.orderHistory = response.object.orderInfo;
        this.itemCount = response.object.itemCount;
        // this.orderHistoryArray.push(this.orderHistory);
        if (this.orderHistory.length > 0) {
          this.orderHistoryArray.push(this.orderHistory);
        }
       
        setTimeout(() => {
          if (this.orderHistory.length!=0) {
            const scrollIndex = this.orderHistory.length - 1;
            const orderInfoId = 'orderHistoryArray_' + (this.offset/this.itemPerPage);
            const el = document.getElementById(orderInfoId);
            
           
           
          }
        }, 150);
      }

      },
      error:(error)=>{


      }
        

    })
    
   
  }

  showMoreClick() {

    this.listCount = this.listCount + 1;
    this.offset = (this.listCount - 1) * this.itemPerPage;
    this.getOrderHistory();
  
  
  }


  hasItemsToShowMore(): boolean {
    return this.orderHistoryArray.length > 0 &&
           this.orderHistoryArray.length < this.itemCount &&
           this.orderHistoryArray[this.orderHistoryArray.length - 1].length >= this.itemPerPage;
  }

 
  orderDetails(orderId: any) {
    
    this.router.navigate(['/order-details'],{ queryParams: {'id': orderId} });
   
  }

  

  
}
