import { Component, Inject, Injectable } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageDialogService {

  constructor(private dialog: MatDialog) { }

  /**
   * Shows the confimation dialog
   * @param dialogData 
   * @returns 
   */
  showConfirmDialog(dialogData: ConfirmDialogModel): Observable<any> {


      const dialogRef = this.dialog.open(MessageDialogComponent, {
        panelClass:'confirm-dialog',
        data: dialogData,
        disableClose:true
      });

      return  dialogRef.afterClosed().pipe();

  }

    /**
   * Shows the confimation dialog
   * @param dialogData 
   * @returns 
   */
    shoNotificationDialog(dialogData: NotificationDialogModel): Observable<any> {
 
        var data={message:dialogData.message,title:dialogData.title,yesButtonCaption:dialogData.yesButtonCaption,showNoButton:false};
        const dialogRef = this.dialog.open(MessageDialogComponent, {
          panelClass:'confirm-dialog',
          data: data,
          disableClose:true
        });
  
        return  dialogRef.afterClosed().pipe();

    }

}

/**
 * Component to display the
 */
@Component({
    selector: 'message-dialog',
    styles: 'p{white-space:pre-line;justify-content: center}::ng-deep{.mdc-dialog__surface{flex-shrink: revert;}}',
    template: `
  <section>
    <h5 class="font-secondary mb-4">{{data.title}}</h5>
    <p>{{data.message}}</p>
    <div class="text-end pt-1 font-secondary">
      <button class="btn btn-outline-dark py-2 px-4 rounded-pill me-2" (click)="onConfirm()">{{yesButtonCaption}}</button>
      @if(showNoButton){
      <button class="btn btn-primary py-2 px-4 rounded-pill" (click)="onDismiss()">{{noButtonCaption}}</button>
      }
    </div>
  </section>`,
    standalone: false
})

export class MessageDialogComponent {

  title: string;
  message: string;
  showNoButton:boolean=true;
  noButtonCaption: string='No';
  yesButtonCaption: string='Yes';

  constructor(public dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.showNoButton= (data.showNoButton !==  undefined && data.showNoButton == false ) ? false : true;
    this.noButtonCaption=(data.noButtonCaption !==  undefined) ? data.noButtonCaption : 'No';
    this.yesButtonCaption=(data.yesButtonCaption !==  undefined ) ? data.yesButtonCaption : 'Yes';
  }

  ngOnInit() {
  }

  /**
   * Close the dialog, return true
   */
  onConfirm(): void {
    this.dialogRef.close(this.yesButtonCaption);
  }

  /**
   * Close the dialog, return false
   */
  onDismiss(): void {
    this.dialogRef.close(this.noButtonCaption);
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {
  noButtonCaption?: string='No';
  yesButtonCaption?: string='Yes';
  constructor(public title: string, public message: string) {}
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class NotificationDialogModel {
  yesButtonCaption: string='Yes';
  constructor(public title: string, public message: string) {
  }
}
