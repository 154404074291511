
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SESSION_APPLIED_REWARDS, SESSION_CUSTOMER_ACTIVE_REWARDS } from '../../../app.constants';
import { NotificationService } from '../../../services/shared/notifications.service';
import { environment } from '../../../environments/environment';
import { StorageService } from '../../../services/storage/storage.service';


@Component({
    selector: 'app-my-reward-card',
    imports: [],
    templateUrl: './my-reward-card.component.html',
    styleUrl: './my-reward-card.component.scss'
})
export class MyRewardCardComponent {
  @Input() reward!: any;
 
  rewardsData: any;
  appliedRewards:any=[];
  crmAssetsUrl= environment.crm.assetsUrl;
  hasImageError:boolean=false;
  isAppliedReward:boolean=false;

  @ViewChild('rewardImg')
  rewardImgRef!: ElementRef<HTMLImageElement>; // Reference to the image
  constructor(private storageService:StorageService,
    private notificationService:NotificationService){}

  ngOnInit(){
    // this.rewardsData = JSON.parse(this.storageService.loadFromSession(SESSION_CUSTOMER_ACTIVE_REWARDS)); 
    this.rewardsData = this.storageService.loadFromSession(SESSION_CUSTOMER_ACTIVE_REWARDS);
    this.rewardsData = (this.rewardsData !== null && this.rewardsData != undefined) ? JSON.parse(this.rewardsData) : [];
      this.appliedRewards = JSON.parse(this.storageService.loadFromSession(SESSION_APPLIED_REWARDS));  
      if(this.appliedRewards==undefined){
        this.appliedRewards=[];
      } 
      this.rewardsData.forEach((reward:any) => {
      if(reward.reward_eligibility == 2 && reward.discount.activation_mode==0){
        this.rewardAutoApply(reward);
      }
    });
  }

  rewardAutoApply(reward:any){
    this.appliedRewards.push(reward)
    this.storageService.saveToSession(SESSION_APPLIED_REWARDS, JSON.stringify(this.appliedRewards));
    this.notificationService.notifyRewardApplied(this.appliedRewards);
  }

  ngAfterViewInit() {
    const image = this.rewardImgRef.nativeElement;
    image.onerror = () => {
      this.hasImageError = true;
    };
  }

/**
   * Add the reward to the applied rewards and save to session
   * @param reward 
   */
applyReward(reward: any): void {
  this.appliedRewards.push(reward)
  this.storageService.saveToSession(SESSION_APPLIED_REWARDS, JSON.stringify(this.appliedRewards));
  this.notificationService.notifyRewardApplied(this.appliedRewards);
}

/**
 * Remove the given reward from the array and save to session
 * @param reward 
 */
removeReward(reward: any) {
  this.appliedRewards = this.appliedRewards.filter((rw: any) => rw.customer_reward_id !== reward.customer_reward_id);
  this.storageService.saveToSession(SESSION_APPLIED_REWARDS, JSON.stringify(this.appliedRewards));
  this.notificationService.notifyRewardRemoved(this.appliedRewards);
}

  isRewardApplied(reward: any): boolean {
    var isRewardApplied = this.appliedRewards.some((rw: any) => rw.customer_reward_id === reward.customer_reward_id);
    return isRewardApplied;
  }
}
