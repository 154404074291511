import { Component, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
} from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms"; // For form controls
import { MatButtonModule } from "@angular/material/button";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { CartService } from "../../../services/cart/cart.service";
import { CustomerService } from "../../../services/customer/customer.service";
import { response } from "express";

import { MessageDialogComponent } from "../message-dialog/message-dialog.component";
import { WeekPassword } from "../../signup/signup.component";

@Component({
    selector: "app-password-dialog",
    imports: [
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
    ],
    templateUrl: "./password-dialog.component.html",
    styleUrl: "./password-dialog.component.scss"
})
export class PasswordDialogComponent {
  password: string = "";
  customer: any = {};
  passwordChangeForm: FormGroup;
  confirmPassError = "";
  passwordInfo: any;
  passwordChangeStatus: number=0;
  constructor(
    private dialogRef: MatDialogRef<PasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private cartService: CartService,
    private customerservice: CustomerService,
    private dialog: MatDialog
  ) {
    const strongPasswordRegx: RegExp = /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;
    this.passwordChangeForm = new FormGroup({
      currentPassword: new FormControl("", Validators.required),
      newPassword: new FormControl("", [Validators.required,Validators.pattern(strongPasswordRegx)]),
      confirmPassword: new FormControl("", Validators.required),
    });
  }

  ngOnInit() {
    this.customer = this.cartService.getCustomer();
  }

  /*
  Password Validation Method
  */
  get passwordFormField() {
    return this.passwordChangeForm.get('newPassword');
  }
  /**
   *
   * to change the password of customer
   */
  changePassword(): void {
    const passwordStrength = ((this.passwordFormField?.value?.match('^(?=.*[A-Z])')) && (this.passwordFormField?.value?.match('(?=.*[a-z])'))
      && (this.passwordFormField?.value?.match('(.*[0-9].*)')) && (this.passwordFormField?.value?.match('(?=.*[!@#$%^&*])'))
      && (this.passwordFormField?.value?.match('.{8,}'))) ? true : false;
    if (!passwordStrength) {
      this.dialog.open(WeekPassword, {
        panelClass: 'confirm-dialog'
      });
    }
    else if (this.passwordChangeForm.valid) {
      if (!this.passwordMatchCheck()) {
        return;
      }
      const passwordInfo = {
        customerName: this.customer.email,
        password: this.passwordChangeForm.controls["confirmPassword"].value,
        oldPassword: this.passwordChangeForm.controls["currentPassword"].value,
      };
      this.passwordChangeStatus = 1
      this.customerservice.changePassword(passwordInfo).subscribe({
        next: (response) => {
          this.passwordChangeStatus = 0
          const message = response.message;
          this.showDialog(message);
          if (response.success == true) {
            this.dialogRef.close();
          }
        },
        error: (error) => {
          console.error("Error changing password:", error);
        },
      });
    }
  }

  /**
   * to close password confirmation dialog box
   */
  close(): void {
    this.dialogRef.close();
  }

  onPasswordChange(passType: string): void {
    this.passwordMatchCheck();
  }

  /**
   *
   * to check the new and confirm password
   */
  passwordMatchCheck(): boolean {
    this.confirmPassError = "";
    const confirmPass =
      this.passwordChangeForm.controls["confirmPassword"].value;
    const newPass = this.passwordChangeForm.controls["newPassword"].value;
    if (confirmPass !== "" && confirmPass !== newPass) {
      this.confirmPassError =
        "New password and confirmation password don't match.";
      return false;
    }
    return true;
  }

  /**
   *
   * to display password confirmation dialog
   */
  showDialog(message: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { title: "PASSWORD CHANGE", content: message };
    dialogConfig.panelClass = "app-message-dialog";
    const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);
  }
}
