import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OOModule } from '../../modules/oo.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { CartService } from '../../services/cart/cart.service';
import { DateTimeServiceService } from '../../services/shared/date-time-service.service';
import { TimeService } from '../../services/shared/time-service.service';
import { SESSION_BUSINESS_TYPE_LIST, SESSION_CART, SESSION_FILTERED_CATEGORY_ITEM_LIST, SESSION_FILTERED_SALE_ITEM_LIST, SESSION_ITEM_CATEGORY_LIST, SESSION_ONLINE_SERVICE_LIST, SESSION_PICKUP_LOCATION_LIST, SESSION_SELECTED_SHOP_LIST, SESSION_SHOP_LIST } from '../../app.constants';
import { StorageService } from '../../services/storage/storage.service';
import { Router, ActivatedRoute } from '@angular/router';

import moment from 'moment';
import { ItemListService } from '../../services/shared/item-list.service';
import { ShopDetailService } from '../shop-detail/shop-detail.service';
import { NotificationService } from '../../services/shared/notifications.service';
import { GtmService } from '../../services/shared/gtm.service';

@Component({
    selector: 'app-order-schedule',
    imports: [FormsModule, MatFormFieldModule, MatSelectModule],
    templateUrl: './order-schedule.component.html',
    styleUrl: './order-schedule.component.scss'
})
export class OrderScheduleComponent {
  noShopServiceTimeSlot: any;
  holidays: any;
  shopHoliday: any = [];
  endingDate: any;
  startingDate: any;
  isTimeSlot: any;
  timeDelay: any;
  redirectUrl: any;
  currntTimeStr: any;
  minDate = new Date();
  maxDate!: Date;
  shopServices: any;
  selectedShop: any;
  advanceOrderDays: any;
  timeSlotData: any;
  timeSlotDropdwnArray: any = [];
  timeSlots: any = [];
  timeSelected: any;
  orderReadyTime: any;
  selectedBuizType: any;
  isLoading: any;
  pauseMessage: any;
  shopPickupLocation: any = [];
  selectedPickUplocation: any;
  isDateChanged: any;
  dateTimeTitlePrefix = '';
  selectedService: any;
  serviceTitle = '';
  contactLessLocation = '';
  dateArray: any = [];
  selectedDate: any;
  serverTime: any;

  id: any;
  allShopList: any = [];
  shopList: any = [];
  dateList: any;
  localserverTime: any;
  day: any;
  shoptime: any;
  selectedDateOfShop: any;
  t: any;
  confirmData: any;
  msg: any;
navigateUrl:any;
  src: any;
  proceedStatus:number=0;

  @ViewChild('dateSelector') dateSelector!: MatSelect ;
  @ViewChild('timeSelector') timeSelector!: MatSelect ;

  constructor(public dialogRef: MatDialogRef<OrderScheduleComponent>,
    private cartService: CartService,
    private dateTimeServiceService: DateTimeServiceService,
    private timeService: TimeService,
    private storageService: StorageService,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private itemListService: ItemListService,
    private shopDetailService:ShopDetailService,
    private activatedRoute: ActivatedRoute,
    private notificationService:NotificationService,
    private gtmService : GtmService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.src=this.data.src;
    this.selectedService = this.cartService.cart.service;
    // this.selectedService = this.cartService.getCart().service;
    const businessTypes = JSON.parse(this.storageService.loadFromSession(SESSION_BUSINESS_TYPE_LIST));
    if (businessTypes !== null && businessTypes !== undefined) {
    this.selectedBuizType = businessTypes.find((bt: any) => bt.code == this.data.businessType);
    }
    this.isLoading = false;
    this.isTimeSlot = false;
    this.selectedPickUplocation = undefined;
    this.noShopServiceTimeSlot = false;
    this.selectedShop = this.data.shop;
    // this.cartService.setShop(this.selectedShop);
    this.navigateUrl=this.data.redirectUrl;
    this.shopServices = this.selectedShop.shopServices; // this.storage.get(SESSION_SELECTED_SHOP_SERVICES);
    this.selectedService = this.cartService.cart.service;
    const shopService = this.shopServices.find((ss: any) => ss.serviceId == this.selectedService.id);
    this.advanceOrderDays = shopService.advanceOrderDays;
    this.maxDate = this.addDays(new Date(), this.advanceOrderDays);
    this.startingDate = this.shopDetailService.formatDate(new Date());
    this.endingDate = this.shopDetailService.formatDate(this.maxDate);
    const searchParam = { 'id': this.selectedShop.id, 'NumDays': this.advanceOrderDays };
    this.shopHoliday = this.selectedShop.shopDailyWorkingHours.filter((obj: any) => obj.dayType === 1);
    this.serverTime = this.timeService.updateServerTime(); 
    this.setEndDate();
    this.timeSlotData = this.selectedShop.shopDailyTimeSlots;
    this.notificationService.refreshOrderSchedule.subscribe(() => {
      this.setTimeSlotDataByDay();
    });
    this.timeSlots = this.setTimeSlotDataByDay();
    this.getShopPickupLocation();
    this.setServiceTitle();

    // this.utilService.progressService.isLoading = false;
    // this.utilService.setMenuItems(initialMenuItems);
    this.redirectUrl = this.data.redirectUrl;
    if (this.data.msg) {
      if (this.timeSlots[0]?.displayTime){
        this.msg=this.data.msg+this.timeSlots[0]?.displayTime+' shown above.';
      }
      else{
        this.msg="Please select another pick up time.";
      }
    }
  }

  ngOnInit() { }

  /**Get date array by start date and calculated end date , to populate date dropdown */
  getDateArray(startDate: Date, endDate: Date) {
    const dayOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dateArray = [];
    let current = startDate;
    while (current <= endDate) {
      const dateObj = { date: '', label: '', isClosed: false };
      const curDate = new Date(current);
      const dayWeek = dayOfWeek[curDate.getDay()];
      dateObj.date = this.formatDate(curDate);
      this.isShopHoliday(dateObj.date);
      dateObj.label =
        this.getDateDisplayLabel(dateObj.date) + ' ' + '(' + dayWeek + ')';
      if (this.isShopHoliday(dateObj.date) || (this.isShopActiveStatus(dateObj.date) === false)) {
        dateObj.label = dateObj.label + ' - Closed ';
        dateObj.isClosed = true;
      }
      dateArray.push(dateObj);
      current = this.addDays(new Date(current), 1);
    }
    return dateArray;
  }

  formatDate(date: Date): any {
    const dt = date.getDate();
    const month = date.getMonth() + 1;
    const yyyy = date.getFullYear();

    const dd = (dt < 10) ? ('0' + dt) : dt + '';
    const mm = (month < 10) ? ('0' + month) : month + '';

    return (yyyy + '/' + mm + '/' + dd);
  }

  getDateDisplayLabel(dateParam: any) {
    let dateLabel = this.datePipe.transform(dateParam, 'dd/MM/yyyy');
    const compDate = new Date(dateParam);
    const dt = new Date(this.serverTime);
    dt.setHours(0, 0, 0, 0);
    const dayDiff = (compDate.valueOf() - dt.valueOf()) / (1000 * 3600 * 24);
    if (dayDiff === 0) {
      dateLabel = 'TODAY';
    } else if (dayDiff === 1) {
      dateLabel = 'TOMORROW';
    }

    return dateLabel;
  }

  /**
 * select pick up location
 */
  getShopPickupLocation() {

    let pickupLocation = JSON.parse(this.storageService.loadFromSession(SESSION_PICKUP_LOCATION_LIST));
    if (pickupLocation != null && pickupLocation != undefined) {
      this.shopPickupLocation = pickupLocation.filter((so: any) => this.selectedShop.pickupLocation.findIndex((sc: any) => sc.ooPickupLocationId === so.id) >= 0);
      if (this.shopPickupLocation.length > 0) {
        let selectedDefault = this.selectedShop.pickupLocation.find((sco: any) => sco.isDefault === 1);
        let defaultLocation = this.shopPickupLocation.find((sco: any) => sco.id === selectedDefault.ooPickupLocationId);
        if (defaultLocation != null && defaultLocation != undefined) {
          this.selectedPickUplocation = defaultLocation;
        } else {
          this.selectedPickUplocation = pickupLocation[0];
        }
      }
    }
  }

  selectPickupLoc(selectedLocation: any) {
    this.selectedPickUplocation = selectedLocation;
  }

  /**Calculate the end date in date selection dropdown based on advance order days in shop service */
  setEndDate() {
    const startDate = this.getStartDate();
    this.dateArray = this.getDateArray(startDate, this.maxDate);
    const deliveryDate = this.cartService.cart.deliveryDate;
    if (deliveryDate !== null && deliveryDate !== undefined) {
      const dateCheck = this.dateArray.find((sh: any) => sh.date === deliveryDate.date);
      if (dateCheck !== null && dateCheck !== undefined) {
        this.selectedDate = dateCheck;
      } else {
        this.selectedDate = this.dateArray[0];
      }
    } else {
      this.selectedDate = this.dateArray[0];
    }
  }
  /**Add number of days to a date */
  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  setServiceTitle(): any {
    switch (this.selectedService.id) {
      case 1: {
        this.serviceTitle = 'PICKUP ORDER FROM';
        this.dateTimeTitlePrefix = 'PICKUP';
        this.contactLessLocation = 'PICKUP';
        break;
      }
      case 2: {
        this.serviceTitle = 'DELIVER ORDER TO';
        this.dateTimeTitlePrefix = 'DELIVERY';
        break;
      }
      case 3: {
        this.serviceTitle = 'DRIVE THROUGH';
        this.dateTimeTitlePrefix = 'DRIVE THROUGH';
        break;
      }
    }
  }

  onDateChange(event: any): void {
    this.isDateChanged = false;
    this.timeSelected = null;
    this.timeSlots = this.setTimeSlotDataByDay();
    if (this.cartService.cart.deliveryDate !== undefined)
      this.isDateChanged = this.cartService.cart.deliveryDate.date != this.selectedDate.date;
    this.msg="";
  }

  setTimeSlotDataByDay(): any {

    let slotData: any[];
    let count: number;
    const data: any[] = [];
    const tz = this.dateTimeServiceService.timezoneOffset();
    const dt = this.getStartDate();
    const delay = 0;
    const timeSlotData: any[] = [];


    /** If date switch to next day after adding the initial delay set the slotData = [] */

    this.currntTimeStr = dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds(); // now
    slotData = this.slotData();
    if (slotData.length !== 0) {
      // now
      if (this.isCurrentDate() &&
        (new Date(this.dateTimeServiceService.convertTimeToDateTime(this.currntTimeStr)) >=
          (new Date(this.dateTimeServiceService.convertTimeToDateTime(slotData[slotData.length - 1].to))))) {
        slotData = [];
      }
      count = 0;
      let strMin = '';
      let strHour = '';
      for (let i = 0; i < slotData.length; i++) {
        const fromTime = new Date(this.dateTimeServiceService.convertToDateTime(dt, this.convertToValidTimeString(slotData[i].from)));
        const toTime = new Date(this.dateTimeServiceService.convertToDateTime(dt, this.convertToValidTimeString(slotData[i].to)));
        const currntTime = dt;
        this.currntTimeStr = dt.getHours() + ':' + dt.getMinutes();

        while (fromTime <= toTime) {
          strHour = (fromTime.getHours() < 10) ? ('0' + fromTime.getHours()) : (fromTime.getHours() + '');
          strMin = (fromTime.getMinutes() < 10) ? ('0' + fromTime.getMinutes()) : (fromTime.getMinutes() + '');
          const timeStr = strHour + ':' + strMin + ':00';
          if (data.findIndex(e => e === timeStr) === -1) {
            const selectedDate = this.selectedDate.date;
            const possibleDate = this.dateTimeServiceService.formatDate(dt, '/');
            if ((possibleDate === selectedDate && currntTime <= fromTime) || possibleDate !== selectedDate) {
              data[count] = timeStr;
              count++;
            }
          }
          this.timeDelay = slotData[i].delay;
          fromTime.setMinutes(fromTime.getMinutes() + this.timeDelay);
        }

      }
    }
    for (let i = 0; i < data.length; i++) {
      const slot = { time: '', displayTime: this.t };
      slot.time = data[i];
      slot.displayTime = this.datePipe.transform(new Date(this.dateTimeServiceService.convertTimeToDateTime(data[i])), 'h:mm a');
      timeSlotData.push(slot);

    }

    this.noShopServiceTimeSlot = false;
    const dateSelected = new Date(this.selectedDate.date);
    // const dateSelected = new Date();
    let selectedDay = dateSelected.getDay();
    selectedDay = (selectedDay === 0) ? 7 : selectedDay;
    const selectedShopWorkInfo = this.selectedShop.shopDailyWorkingHours.filter((sw: any) => sw.date === this.datePipe.transform(new Date(this.selectedDate.date), "yyyy-MM-dd"));
    const selectedShopWorkArray = (selectedShopWorkInfo.length === 0) ? selectedShopWorkInfo :
      timeSlotData.filter(ts => ts.time >= selectedShopWorkInfo[0].openTime && ts.time <= selectedShopWorkInfo[0].closeTime);
    const currntTime = new Date(this.dateTimeServiceService.convertTimeToDateTime(this.convertToValidTimeString(dt)));

    if (this.isCurrentDate() && (selectedShopWorkInfo.length > 0) && (currntTime >=
      new Date(this.dateTimeServiceService.convertTimeToDateTime(selectedShopWorkInfo[0].openTime))
      && currntTime <= new Date(this.dateTimeServiceService.convertTimeToDateTime(selectedShopWorkInfo[0].closeTime)))) {
      if (timeSlotData.length === 0) {
        this.noShopServiceTimeSlot = true;
      }

    } else {
      this.noShopServiceTimeSlot = false;
    }

    const deliveryDate = this.cartService.cart.deliveryDate;
    const selectedDate = this.selectedDate.date;
    const DeliveryTime = this.cartService.cart.deliveryTime;
    if (DeliveryTime !== null && DeliveryTime !== undefined) {
      const timeCheck = selectedShopWorkArray.find((sh: any) => sh.time === DeliveryTime);
      if (timeCheck !== null && timeCheck !== undefined && deliveryDate.date !== null &&
        deliveryDate.date !== undefined && deliveryDate.date === selectedDate) {
        this.timeSelected = timeCheck;
      } else {
        this.timeSelected = selectedShopWorkArray[0];
      }
    } else {
      this.timeSelected = selectedShopWorkArray[0];
    }
    if (this.timeSelected !== null && this.timeSelected !== undefined && !(this.isShopHoliday(this.selectedDate.date))
    ) {
      this.isTimeSlot = false;
    } else {
      this.isTimeSlot = true;
    }
    /*********to check shop is active or not******/
    if (this.isShopActiveStatus(this.selectedDate.date) === false) {
      this.isTimeSlot = true;
    }
    return selectedShopWorkArray;
  }

  slotData(): any {
    let slotData: any[];
    const dateSelected = this.datePipe.transform(new Date(this.selectedDate.date), "yyyy-MM-dd");

    slotData = this.timeSlotData.filter(
      (obj: any) => (obj.date === dateSelected)
    );

    return slotData;
  }

  diff(start: any, end: any) {
    start = start.split(':');
    end = end.split(':');
    const startDate = new Date(0, 0, 0, start[0], start[1], 0);
    const endDate = new Date(0, 0, 0, end[0], end[1], 0);
    let diff = endDate.getTime() - startDate.getTime();
    const hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    const minutes = Math.floor(diff / 1000 / 60);

    return (hours < 9 ? '0' : '') + hours + ':' + (minutes < 9 ? '0' : '') + minutes;
  }

  isShopHoliday(date: any): any {
    return (this.shopHoliday.findIndex((sh: any) => sh.date === this.datePipe.transform(date, 'yyyy-MM-dd')) > -1) ? true : false;
  }

  isShopActiveStatus(dateObj: any) {
    const inactiveDateFrom = this.selectedShop.inactiveFrom;
    const inacticveDateTo = this.selectedShop.inactiveTo;
    dateObj = this.datePipe.transform(dateObj, 'yyyy-MM-dd');
    const Active = this.getShopActiveStatus(dateObj, inactiveDateFrom, inacticveDateTo);
    return Active;
  }

  getShopActiveStatus(selectedDate: any, inactiveDateFrom: any, inacticveDateTo: any): boolean {
    let ActiveStatus = true;
    if (inactiveDateFrom !== '') {
      if (inactiveDateFrom <= selectedDate) {
        if (inacticveDateTo !== '' && inacticveDateTo < selectedDate) {
          ActiveStatus = true;
        } else {
          ActiveStatus = false;
        }
      }
    }
    return ActiveStatus;
  }

  getShopClosedMsg(): any {
    return 'on ' + this.selectedDate.label.split('-', 1)[0].trim();
  }

  /** Set time slots in time selection dropdown based on the selected date*/
  getStartDate(): Date {
    const dt = new Date(this.serverTime);
    dt.setMinutes(dt.getMinutes());
    dt.setMinutes(dt.getMinutes() + this.selectedBuizType.initialDelay);
    return dt;
  }

  /**Check if selected date is current date */
  isCurrentDate(): boolean {
    const dt = new Date(this.serverTime);
    const slctdDate = new Date(this.selectedDate.date);

    const dateSelected = new Date(slctdDate.getFullYear(), slctdDate.getMonth(),
      slctdDate.getDate());
    dt.setHours(0, 0, 0, 0);
    const isToday = (dateSelected.getTime() === dt.getTime()) ? true : false;
    return isToday;
  }

  redirect() {

    if (this.redirectUrl !== undefined) {
      this.setProductData(this.redirectUrl);
    } else {
      // this.utilService.progressService.isLoading = true;
      this.setProductData('/shopItem');

    }
  }

  setProductData(redirectUrl: any) {
    this.cartService.setBusinessType(this.selectedBuizType);
    this.cartService.setShop(this.selectedShop);
    // this.gtmService.pushEvent(
    //   'select store',
    //   {
    //     storeSelected: this.gtmService.getStore(),  
    //     visitorType : this.gtmService.getVisitorType()
    //   }
    // );
    const businessTypeId = this.cartService.cart.businessType.id;
    const pickUpDate = this.cartService.cart.deliveryDate.date;
    const shopId = this.cartService.cart.shop.id;
    this.updateGTMInfo();
    const filteredSaleitemlist = this.itemListService.loadSaleItemExt(businessTypeId, pickUpDate, this.cartService.cart.shop).subscribe(
      res => {
        const saleItemList = res.object;
        this.storageService.saveToSession(SESSION_FILTERED_SALE_ITEM_LIST, JSON.stringify(saleItemList));
        const categoryList = JSON.parse(this.storageService.loadFromSession(SESSION_ITEM_CATEGORY_LIST));
        const filteredCategory = this.itemListService.getFilteredItemCategoryList(categoryList, saleItemList);
        this.storageService.saveToSession(SESSION_FILTERED_CATEGORY_ITEM_LIST, JSON.stringify(filteredCategory));
        // this.utilService.progressService.isLoading = false;
        this.close(true);
        this.router.navigate([redirectUrl]);
      });
  }
  cancel(){
    let isProceed = false;
    // this.cartService.setDeliveryDate(this.selectedDate);
    // this.cartService.setDeliveryTime(this.timeSelected.time);
    // this.setPickUpLocationToCart();
    this.close(false);
  }
  addDateTimeToCart(): any {
    let isProceed = true;
    this.proceedStatus=1
    if (this.selectedDate.isClosed === true) {
      isProceed = false;
      this.proceedStatus=0
      this.msg="Invalid Selection,Select time from the above list"
      // this.openDialog('Invalid Selection', 'Select time from the above list');
    } else if (this.timeSelected !== null && this.timeSelected !== undefined) {
      /**
          * confirm the warnText of pickuplocation.
          */
      if (this.selectedPickUplocation !== undefined && this.selectedPickUplocation.warnText !== null && this.selectedPickUplocation.warnText.toString().trim().length > 0) {
      // this.confirmData={
      //   header: 'Pickup Alert',
      //   message: this.selectedPickUplocation.warnText,
      //   accept: () => {
          this.isSelectedTimeNotinpausetimeList(this.selectedShop.id, this.selectedDate, this.timeSelected.time);
      //   }
      // };
      } else {
      this.isSelectedTimeNotinpausetimeList(this.selectedShop.id, this.selectedDate, this.timeSelected.time);
      }
    } else {
      isProceed = false;
      this.proceedStatus=0;
      this.msg="Invalid Selection, Select time from the above list"
      // this.utilService.openDialog('Invalid Selection', 'Select time from the above list');
    }
    if(this.src=='reorder' && isProceed){
      this.close(true);
    }
    else{
    return isProceed;
    }
  }

  isSelectedTimeNotinpausetimeList(selectedShopId: any, selectedDate: any, selectedTime: any) {
    this.pauseMessage = "";
    let selectedDateFt = this.datePipe.transform(selectedDate.date, 'yyyy-MM-dd');
    // moment(selectedDate.date,'YYYY-MM-DD');
    // this.utilService.progressService.isLoading = true;
    this.itemListService.selectedTimeValidity(selectedShopId, selectedDateFt, selectedTime).subscribe(
      res => {
        if (!res.success && res.object != null) {
          this.proceedStatus=0
          let pauseMsg = res.message;
          this.pauseMessage = pauseMsg.replace("<shop_name>", this.selectedShop.name);
          this.msg='Information '+this.pauseMessage;
          // this.utilService.openDialog('Information', this.pauseMessage);
          // this.utilService.progressService.isLoading = false;
        } else {
          this.proceedStatus=1
          //   isNotInPauseList = true;
          this.cartService.setDeliveryDate(this.selectedDate);
          this.cartService.setDeliveryTime(this.timeSelected.time);
          this.setPickUpLocationToCart();
          /**
           * out of stock list by shop_id and pickupdate
           * returns  array list of ooSaleItemIds.
           */
          let inputParam = { "shop_id": selectedShopId, "pickUp_date_time": selectedDateFt + " " + selectedTime };
          this.dateTimeServiceService.getOutofStockByShop(inputParam);
          this.redirect();
        }
      });
  }

  setPickUpLocationToCart() {
    if (this.selectedPickUplocation !== undefined) {
      this.cartService.setPickupLocation(this.selectedPickUplocation.id);
      this.cartService.setRemarks(this.selectedPickUplocation.description);
    }
  }

  updateGTMInfo() {
    // this.gtmService.pushEvent(
    //   'select_date',
    //   {
    //     storeSelected: this.gtmService.getStore(),
    //     visitorType: this.gtmService.getVisitorType(),
    //     dateSelected: this.gtmService.geDeliveryDate(),
    //     timeSelected: this.gtmService.getDeliveryTime(),
    //     preOrder: this.gtmService.isPreOrder()
    //   }
    // );

    // if (this.gtmService.previousUrl.startsWith("/category-list") && this.isDateChanged) {
    //   this.gtmService.pushEvent(
    //     'page_engagement',
    //     {
    //       actionType: 'Back to Date and Time',
    //       storeSelected: this.gtmService.getStore(),
    //       visitorType: this.gtmService.getVisitorType(),
    //       dateSelectd: this.gtmService.geDeliveryDate(),
    //       timeSelected: this.gtmService.getDeliveryTime(),
    //       preOrder: this.gtmService.isPreOrder(),
    //       dateChanged: this.isDateChanged
    //     });
    // }
  }

  /**
   * Handle back button press
   */
  handleGTMPageEngagement(previousUrl: string) {
    // if (previousUrl.startsWith('/storefinder')) {
    //     this.gtmService.pushEvent(
    //       'page_engagement',
    //       {
    //         actionType: 'Back to store page',
    //         storeSelected: this.gtmService.getStore(),
    //         visitorType: this.gtmService.getVisitorType(),
    //       });
    //   }
  }

  /**set selected time as time selection drop down value changes */
  onTimeChange(event: any) {
    this.timeSelected = event.value;
    this.msg=""
  }

  convertToValidTimeString(date: any): any {

    let dateStr = '';
    let hr = '';
    let min = '';
    let sec = '';
    if (date instanceof Date) {
      hr = (date.getHours() < 10) ? ('0' + date.getHours()) : date.getHours() + '';
      min = (date.getMinutes() < 10) ? ('0' + date.getMinutes()) : date.getMinutes() + '';
      sec = (date.getSeconds() < 10) ? ('0' + date.getSeconds()) : date.getSeconds() + '';
    } else {
      hr = date.split(':')[0];
      min = date.split(':')[1];
      sec = date.split(':')[2];
    }
    dateStr = hr + ':' + min + ':' + sec;
    return dateStr;
  }

  close(data:any) {
    this.dialogRef.close(data);
    
  }
  
  /**
   * Show the dropdown when click anywhere in the div
   */
  openDateSelection():void{
    if(this.dateSelector!==undefined){
      this.dateSelector.open();
    }
  }

  /**
   * Show the dropdown when click anywhere in the div
   */
  openTimeSelection():void{
    if(this.timeSelector!==undefined){
      this.timeSelector.open();
    }
  }
}

