import { Component, Inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CartService } from '../../services/cart/cart.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../services/storage/storage.service';
import { PaymentInitService } from '../payment-init/payment-init.service';
import { OrderConfirmationService } from '../confirm-orders/order-confirmation.service';
import { environment } from '../../environments/environment';
import { SESSION_APPLIED_REWARDS, SESSION_LAST_SUCCESS_TAX_INVOICE_NO, SESSION_LAST_SUCCESS_TXN_ID, SESSION_LAST_SUCCESS_TXN_RECEIPT, SESSION_LAST_TXN_RECEIPT_DOWNLOADED } from '../../app.constants';
import { RewardService } from '../../services/shared/reward.service';
import { NotificationService } from '../../services/shared/notifications.service';
import { ConfirmDialogModel, MessageDialogService, NotificationDialogModel } from '../../services/shared/message-dialog.service';

@Component({
    selector: 'app-payment-success',
    imports: [],
    templateUrl: './payment-success.component.html',
    styleUrl: './payment-success.component.scss'
})
export class PaymentSuccessComponent {
  cart: any;
  result: any;
  paymentResult: any;
  htmlData: SafeHtml | undefined;
  paymentDetails: any;
  retryId: any;
  retryCount:any;
  retryIntrvl: number;
  rewardsData: any;
  recentOrderList: any;
  constructor(private cartService: CartService, private router: Router, 
    private storageService: StorageService,
    private route: ActivatedRoute, 
    private paymentInitService: PaymentInitService, 
    private sanitizer: DomSanitizer,
    private confirmationService: OrderConfirmationService,
    private rewardService: RewardService,
    private notificationService:NotificationService,
    private messageDialogService:MessageDialogService
    ) {
    this.retryIntrvl= environment.oos.statusRetryInterval*1000;
  }

  processCart() {

    try {
      this.cart = this.cartService.cart;
      // const customerId = this.cart.customer.id;
      this.cartService.cart.items = [];
      this.cartService.cart.remarks = undefined;
      this.cartService.cart.pickupLocationId = undefined;
      this.cartService.setToSession();
      // this.rewardService.loadCustomerRewards(this.cartService.cart.customer?.id).subscribe(reward => {
      //   if(reward.data.customer_reward!==undefined && reward.data.customer_reward!==null){
      //     const activeRewards = reward.data.customer_reward.filter((cr:any) => cr.customer_status==1);
      //     this.rewardsData =activeRewards;
      //   }else{
      //     this.rewardsData = undefined;
      //   }
      //   console.log(this.rewardsData);
      // });
    } catch (err) {

    }

  }

  ngAfterViewInit() {
    // this.cart = this.cartService.cart;
    this.retryCount = 0;
    this.retryId = undefined;
    this.getOrderStatus();
    const txnId = this.route.snapshot.queryParams['txnid'];
    this.updateGTMInfo(txnId);
    this.processCart();
    
  }

  getOrderStatus(){
    const pid = this.route.snapshot.queryParams['pid']; //this.cart.customer.id;
    const sucessResultParam = this.route.snapshot.queryParams['result'];
    const txnId = this.route.snapshot.queryParams['txnid'];
    const userId = this.route.snapshot.queryParams['userid'];
    
    this.paymentInitService.getOrderStatus(sucessResultParam, pid,txnId,this.retryId,userId).subscribe(
      res => {
        this.result = res.success;
        this.paymentDetails = res.object;
        this.htmlData = undefined;
        if (this.result) {

           this.htmlData='';
          if (this.paymentDetails !== undefined && this.paymentDetails.txnId !== undefined && txnId !== null && this.paymentDetails.mailContent !== undefined && this.paymentDetails.mailContent.length > 0) {
            this.storageService.saveToSession(SESSION_LAST_SUCCESS_TAX_INVOICE_NO,this.paymentDetails.taxInvoiceNumber.toString());
            this.storageService.saveToSession(SESSION_LAST_SUCCESS_TXN_ID,this.paymentDetails.txnId.toString());
            this.storageService.saveToSession(SESSION_LAST_SUCCESS_TXN_RECEIPT,this.paymentDetails.mailContent);
            this.storageService.saveToSession(SESSION_LAST_TXN_RECEIPT_DOWNLOADED, false);
            this.htmlData = this.sanitizer.bypassSecurityTrustHtml(this.paymentDetails.mailContent);

            this.rewardService.loadCustomerRewards(this.cartService.cart.customer?.id).subscribe(reward => {
              if (reward.data.customer_reward !== undefined && reward.data.customer_reward !== null) {
                const activeRewards = reward.data.customer_reward.filter((cr: any) => cr.customer_status == 1);
                this.rewardsData = activeRewards;
              } else {
                this.rewardsData = undefined;
              }
              var appliedRewards:any =[];
              this.storageService.saveToSession(SESSION_APPLIED_REWARDS, JSON.stringify(appliedRewards));
            });
          }
          // this.processCart();
          if(this.htmlData!==''){
            
            this.router.navigate(['/view-receipt'],{ queryParams: { 'tn': this.paymentDetails.taxInvoiceNumber}});
          }

        } else {
          if(res.errorCode=='INVALID_ORDER'){
            this.processCart();
            var dailogData: NotificationDialogModel = {
              message: `Your last order was found processed. Please check your mail.`,
              title:"ORDER PROCESSED",
              yesButtonCaption:"Ok"
            }
        
            this.messageDialogService.shoNotificationDialog(dailogData).subscribe(result => {
              if (result == "Ok") {
                this.router.navigate(['/order']);
              }
            });

            // this.processCart();
            // this.confirmationService.confirm({
            //   key: 'payment-success',
            //   header: 'ORDER PROCESSED',
            //   message: "Your last order was found processed. Please check your mail.",
            //   accept: () => {
            //     this.router.navigate(['/order']);
            //   },
            // });
          }else if(res.errorCode == "RETRY"){
            
            if(environment.oos.statusRetryLimit>this.retryCount){
            setTimeout(()=>{ // this will make the execution after 3000ms
              
              this.retryId = res.object;
              this.getOrderStatus();

              /**Retrun the txnId received from the gateway response,
              send it back to the client side and retry with the txnId */

              
              this.retryCount++;
            },this.retryIntrvl);           
            
          }else{

            var dlgData: NotificationDialogModel = {
              message: `Your payment is successful. Please check your mail for the receipt.`,
              title:"Receipt preview not available.",
              yesButtonCaption:"Ok"
            }

            this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => {
              if (result == "Ok") {
                this.processCart();
                this.router.navigate(['/order']);
              }
            });
            // this.confirmationService.confirm({
            //   header:'Receipt preview not available.',
            //   message: 'Your payment is successful. Please check your mail for the receipt.',
            //   accept: () => {
            //     this.processCart();
            //     this.router.navigate(['/order']);
            //   },
            //   });
          }
            return;
          } else{
            this.processCart();
            this.confirmationService.confirm({
              key: 'payment-success',
              header: 'FAILED TO PROCESS ORDER',
              message: res.message,
              accept: () => {
                this.router.navigate(['/order']);
              },
            });
          }
        }
      }
      
    );
  }

  updateGTMInfo(txnId:any){
    // const cartParam = this.cartService.getCartParam();


    // this.gtmService.pushEvent(
    //   '',
    //   {
    //     userId: this.gtmService.getCustomer(),
    //     visitorType: this.gtmService.getVisitorType(),
    //     dateSelectd: this.gtmService.geDeliveryDate(),
    //     timeSelected: this.gtmService.getDeliveryTime(),
    //     preOrder: this.gtmService.isPreOrder()

    //   });
    //  let taxP=cartParam.items[0].tax1Percentage;

    // let items = [];
    // for (const cartItem of cartParam.items) {
    //   let saleItemInfo = this.gtmService.getSaleItem(cartItem.ooSaleItemId);
    //   items.push({
    //     item_id: saleItemInfo.id,
    //     item_name: saleItemInfo.name,
    //     affiliation: this.gtmService.getStore(),
    //     item_brand: this.gtmService.getItemParentCategory(saleItemInfo).name,
    //     item_category: this.gtmService.getItemSubCategory(saleItemInfo).name,
    //     item_variant: '',
    //     price: cartItem.netTotal,
    //     quantity: cartItem.quantity

    //   });
    //   //{selectedCustomization:selectedCustomization,total:custTotalPrice};
    //   let customization = this.getSelectedCustomizations(cartItem);

    //   if (customization.selectedCustomization.length > 0) {
    //     items.push(
    //       {
    //         item_id: saleItemInfo.id,
    //         item_name: saleItemInfo.name,
    //         item_category2: (customization.selectedCustomization.length > 0) ? customization.selectedCustomization[0].nameToPrint : '',
    //         item_category3: (customization.selectedCustomization.length > 1) ? customization.selectedCustomization[1].nameToPrint : '',
    //         item_category4: (customization.selectedCustomization.length > 2) ? customization.selectedCustomization[2].nameToPrint : '',
    //         item_category5: (customization.selectedCustomization.length > 3) ? customization.selectedCustomization[3].nameToPrint : '',
    //         price: customization.total * cartItem.quantity,
    //         quantity: cartItem.quantity
    //       }
    //     );
    //   }
    // }
    // let totalAmount = this.cartService.getCartTotal();
    // let tax = totalAmount - totalAmount / (1 + (taxP / 100));
    // this.gtmService.pushG4AEvent(
    //   'purchase',
    //   {
    //     ecommerce: {
    //       transaction_id: txnId,
    //       currency: 'NZD',
    //       value: totalAmount,
    //       tax: tax.toFixed(2),
    //       items: items
    //     }
    //   });

  }
}
