import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { CustomerService } from '../../services/customer/customer.service';

import { OOModule } from '../../modules/oo.module';
import { StorageService } from '../../services/storage/storage.service';
import { environment } from '../../environments/environment';
import { NotificationService } from '../../services/shared/notifications.service';
import { CartService } from '../../services/cart/cart.service';
import { Router } from '@angular/router';
import { SESSION_APPLIED_REWARDS, SESSION_AUTHENTICATED_CUSTOMER, SESSION_CUSTOMER_ACTIVE_REWARDS, SESSION_CUSTOMER_REWARDS } from '../../app.constants';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  guestCustomerUrl: string;

  constructor(private http: HttpClient,
    private customerService:CustomerService,
    private storageService:StorageService,
    private notificationService:NotificationService,
    private cartService:CartService,
    private router:Router) {
      this.guestCustomerUrl = environment.oos.url + '/customer/guest-customer';
    }

  /**
   * 
   * @param customer 
   */
  authenticateCustomer(customer: any):Observable<any> {
    return this.customerService.authenticateCustomer(customer)
  }

  authenticatesocialCustomer(socialcustomer:any):Observable<any> {
    return this.customerService.authenticateSocialCustomer(socialcustomer)
  }

  rememberCustomer(customerId:any):Observable<any> {
    return this.storageService.saveToLocalStorage(customerId)
  }

  forgotPassword(emailData:any):Observable<any>{
    return this.customerService.forgotPassword(emailData)
  }

  guestCustomer(customer: any): Observable<any> {
    return this.http.post<any>(this.guestCustomerUrl, customer)
    .pipe(
    );
  }

  
  /* When LOGOUT the session and Local storages are cleared*/
  logout() {
    // this.storageService.clearSession();
    this.storageService.clearSessionByName(SESSION_APPLIED_REWARDS);
    this.storageService.clearSessionByName(SESSION_AUTHENTICATED_CUSTOMER);
    this.storageService.clearSessionByName(SESSION_CUSTOMER_ACTIVE_REWARDS);
    this.storageService.clearSessionByName(SESSION_CUSTOMER_REWARDS);
    this.storageService.clearlocalStorage();
    this.cartService.resetCustomer();
    this.cartService.resetCart();
    this.notificationService.notifyLoginChange('out');
    this.router.navigate(["/"], { skipLocationChange: false });
  }

}
