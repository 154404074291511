import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Router } from '@angular/router';
import { LoginComponent } from '../../login/login.component';
;

@Component({
    selector: 'app-registratio-succes-message-dialog',
    imports: [],
    templateUrl: './registratio-succes-message-dialog.component.html',
    styleUrl: './registratio-succes-message-dialog.component.scss'
})
export class RegistratioSuccesMessageDialogComponent {
  reward:any;
  constructor(
    private dialogRef: MatDialogRef<RegistratioSuccesMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private router: Router,public dialog: MatDialog,
  ) {
    this.reward=data.reward;
  }


  /**
   * to close the dialog
   */
  close() {
    this.dialogRef.close();
    // this.router.navigate(['/LoginComponent']);

  }


  loginDialog() {
    this.dialog.open(LoginComponent, {
      panelClass: "login-dialog",
    });
    this.close();
  }
}
