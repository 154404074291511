import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { NewVersionCheckerService } from './new-version-checker.service';
import { Router, RouterOutlet, NavigationStart } from '@angular/router';
import { HeaderComponent } from './pages/header/header.component';
import { FooterComponent } from './pages/footer/footer.component';

import './services/fcm/firebase-initialization';
import { FcmService } from './services/fcm/fcm.service';
import { OOModule } from './modules/oo.module';
import { TimeService } from './services/shared/time-service.service';
import { UtilService } from './services/shared/util.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StorageService } from './services/storage/storage.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-root',
    imports: [CommonModule, RouterOutlet, HeaderComponent, OOModule],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  title = "St Pierres Online";
  hasUpdate: any;
  @Input() containerClasses: any;
  constructor(private swupdate: SwUpdate,
    private fcmService: FcmService,
    private timeService: TimeService,
    private router: Router,
    private utilService: UtilService,
    public dialog: MatDialog,
    public storageService: StorageService,
    public newVersionCheckerService: NewVersionCheckerService
  ) {
    this.newVersionCheckerService.applyUpdate();
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
    //     if (!this.utilService.validateSession()) {
    //       const timeExpiredUrls = ['/shoppingCart', '/shopItem', '/shopList'];
    //       const isExpired = timeExpiredUrls.findIndex(element => (event.url.indexOf(element) === 0));
    //       if (isExpired > -1) {
    //         this.dialog.open(SessionExpired,{
    //           panelClass:'confirm-dialog'
    //         });
    //         this.storageService.clearSession();
    //         // this.router.navigate(['/']);
    //         window.location.href='/';
    //         return;
    //       }
    //     }
        this.timeService.startTimeService();
      }
    });
  }

  ngOnInit() {
    this.fcmService.requestPermission();
  }

 
}

@Component({
    selector: 'session-expired',
    template: `
  <section>
    <h5 class="font-secondary mb-4">Time Expired</h5>
    <p>The session time has expired</p>
    <div class="text-end pt-1 font-secondary">
      <button class="btn btn-outline-dark py-2 px-4 rounded-pill me-2" (click)="close()">Ok</button>
    </div>
  </section>`,
    standalone: false
})
export class SessionExpired {
  constructor(public dialogRef: MatDialogRef<SessionExpired>) { }
  close() {
    this.dialogRef.close()
  }
}