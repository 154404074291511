import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentInitService } from '../payment-init/payment-init.service';
import { CartService } from '../../services/cart/cart.service';
import { UtilService } from '../../services/shared/util.service';


@Component({
    selector: 'app-payment-fail',
    imports: [],
    templateUrl: './payment-fail.component.html',
    styleUrl: './payment-fail.component.scss'
})
export class PaymentFailComponent {
  result: any;
  errorPage: any;
  txnId:any;
  currentTime: string = '';
  paymentId: any;
  constructor( private route: ActivatedRoute, private paymentInitService: PaymentInitService,
    private cartService: CartService, private router: Router,
    private utilService:UtilService,) {
   }

   
   
  ngOnInit() {
    // Update current time on initialization
    this.updateCurrentTime();
    this.utilService.scrollToTop();
    const pid = this.route.snapshot.queryParams['pid']; 
    const sucessResultParam = this.route.snapshot.queryParams['result'];
    const txnId = this.route.snapshot.queryParams['txnid'];
    const retryId = undefined;
    this.paymentId =this.route.snapshot.queryParams['txnid'];
    const userId = this.route.snapshot.queryParams['userid'];

    this.paymentInitService.getOrderStatus(sucessResultParam, pid, txnId,retryId,userId).subscribe(
         res => {
      this.result = res.success;
         if (this.result) {
       } else {
      this.errorPage = res.message;
       }
     }
   );
   
  }

  onBackButttonPressed(): Boolean {
    this.router.navigate(['/orderConfirmation']);
    return true;
  }

  /**navigate back to the shopping cart page */
  OnBack(){
    this.router.navigate(['/shoppingCart']);
  }

   /**navigate back to the shopping cart page  */
  OrderDetailsBtn(){
    this.router.navigate(['/shoppingCart']);
  }

   /**navigate back to the confirmOrders page */
  RetryBtn(){
    this.router.navigate(['/confirmOrders']);
  }

  updateCurrentTime() {
    this.currentTime = new Date().toLocaleString(); // Adjust format as needed
  }

  onHome():void{
    this.router.navigate(['/']); 
  }
}
