import { Component, Inject, NgModule, OnDestroy, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { MatInputModule } from "@angular/material/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms"; // For form controls
import { MatButtonModule } from "@angular/material/button";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { CustomerService } from "./../../services/customer/customer.service";
import {  MatDialog,  MatDialogConfig,} from "@angular/material/dialog";
import { MessageDialogComponent } from "../my-account/message-dialog/message-dialog.component";
import { environment } from "../../environments/environment";
// import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, ReCaptchaV3Service,} from "ng-recaptcha";
import { CartService } from "../../services/cart/cart.service";

import { StorageService } from '../../services/storage/storage.service';
import { SESSION_CART, SESSION_CUSTOMER_ACTIVE_REWARDS } from "../../app.constants";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaModule, ReCaptchaV3Service } from "ng-recaptcha-2";

@NgModule({
  imports: [
    RecaptchaModule
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.oos.siteKey
    },ReCaptchaV3Service
  ]
})
export class ContactModule { }

@Component({
    selector: "app-contact",
    imports: [
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        RecaptchaModule,
        ContactModule
    ],
    templateUrl: "./contact.component.html",
    styleUrl: "./contact.component.scss"
})

export class ContactComponent implements OnInit, OnDestroy {

  contanctDataForm: FormGroup;
  feedbackdata: any;
  private reCaptchaAction = 'feedback';
  recaptchaToken: any = "";
  customer: any = {};
  loginStatus: boolean = false;
  feedbackStatus:number = 0;

  constructor(
    private cartService: CartService,
    private location: Location,
    private recaptchaV3Service: ReCaptchaV3Service,
    private dialog: MatDialog,
    private customerservice: CustomerService,
    private storageService: StorageService,
    @Inject(RECAPTCHA_V3_SITE_KEY) private siteKey: string

  ) {
    this.contanctDataForm = new FormGroup({
      fullName: new FormControl("", Validators.required),
      email: new FormControl("", [Validators.required, Validators.email]),
      message: new FormControl("", [Validators.required]),
      phoneNumber: new FormControl("", [
        Validators.required,
        Validators.maxLength(13),
        Validators.minLength(9),
      ]),
      storeName: new FormControl(""),
      orderNumber: new FormControl(""),
    });
  }

  ngOnDestroy(): void {
    this.recaptchaToken = null;
  }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // this.recaptchaV3Service.execute(this.reCaptchaAction)
    //   .subscribe(token => {
    //     this.recaptchaToken = token;
    //   });
      this.customer = this.cartService.getCustomer();
      if (this.loginStatus==false) {
        const customer = this.cartService.getCustomer();
        this.contanctDataForm.patchValue({
          fullName: `${customer.firstName || ''} ${customer.lastName || ''}`,
          email: customer.email,
          phoneNumber: customer.phoneNumber,
        });
      }
      
  }
  setMenuOptions() {
    const cart = JSON.parse(this.storageService.loadFromSession(SESSION_CART));
    this.customer = (cart !== null && cart !== undefined) ? cart.customer : undefined;
    this.loginStatus = (this.customer !== null &&
      this.customer !== undefined && this.customer.customerType !== 2);
    
  }
  
  back() {
    this.location.back();
  }
  /**
   * to send feedback of the customer
   */
  sendFeedBackData(): void {
    this.feedbackStatus=1;
    const feedbackdata = {
      from: this.contanctDataForm.controls["email"].value,
      content: this.contanctDataForm.controls["message"].value,
      name: this.contanctDataForm.controls["fullName"].value,
      phoneNumber: this.contanctDataForm.controls["phoneNumber"].value,
      storeName: this.contanctDataForm.controls["storeName"].value,
      orderNumber: this.contanctDataForm.controls["orderNumber"].value,
    };
    if (this.contanctDataForm.valid) {
      this.recaptchaV3Service.execute(this.reCaptchaAction)
      .subscribe(token => {
        this.recaptchaToken = token;
      this.customerservice.feedBackRegestration({ ...feedbackdata, captchaToken: this.recaptchaToken }).subscribe({
        next: (response) => {
          const message = response.message;
          if (response.status === 'SUCCESS') {
            this.feedbackStatus=0;
            this.contanctDataForm.reset(); // Reset the form
            const dialogConfig = new MatDialogConfig();
            dialogConfig.data = {
              title: "Thank you for the feedback.",
              content: response.message //"Your feedback is updated successfully."
            };
            dialogConfig.panelClass = "app-message-dialog";
            const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);
        
            dialogRef.afterClosed().subscribe(() => {
            
              this.location.back(); // Navigate back after dialog closes
            });
          } else {
            const message = response.message;
            this.showDialog({ title: "Failed", content: message });
          }
        },
        error: (error) => {
          this.feedbackStatus=1;
          const message = error.message;
          this.showDialog({ title: "Failed", content: "Failed to send feedback." });
        },
      });
    });
     
    }
  }

  showDialog(message: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data =  message;
    dialogConfig.panelClass = "app-message-dialog";
    const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);
  }

  onMessageKeyUp(event: KeyboardEvent) {
    const message = this.contanctDataForm.get('message')?.value;
    const characterCount = Math.min(message?.length || 0, 800); // Ensure count doesn't exceed 800
    const counterElement = document.querySelector('.mb-0.position-absolute.end-0');
  
    if (counterElement) {
      counterElement.textContent = `${characterCount}/800`;
    }
  }
   /** to prevent typing charaters other than numbers  */
  preventNonNumeric(event: KeyboardEvent) {
    if (!/^[0-9]*$/.test(event.key)) {
      event.preventDefault();
    }
  }
}
