import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OOModule } from '../../modules/oo.module';
import { StorageService } from '../../services/storage/storage.service';
import { SESSION_CUSTOMER_ACTIVE_REWARDS, SESSION_APPLIED_REWARDS } from '../../app.constants';
import { RewardCardComponent } from "./components/reward-card/reward-card.component";

@Component({
    selector: 'app-rewards',
    templateUrl: './rewards.component.html',
    styleUrl: './rewards.component.scss',
    imports: [OOModule, RewardCardComponent]
})
export class RewardsComponent {
  rewardsData: any;
  appliedRewards:any=[];

  @ViewChild('rewardImg')
  rewardImgRef!: ElementRef<HTMLImageElement>; // Reference to the image


  constructor(
    public dialogRef: MatDialogRef<RewardsComponent>,
    private storageService:StorageService) { }

  ngOnInit() {
    this.rewardsData = this.storageService.loadFromSession(SESSION_CUSTOMER_ACTIVE_REWARDS);
    this.rewardsData = (this.rewardsData !== null && this.rewardsData != undefined) ? JSON.parse(this.rewardsData) : [];
      this.appliedRewards = JSON.parse(this.storageService.loadFromSession(SESSION_APPLIED_REWARDS));  
      if(this.appliedRewards==undefined){
        this.appliedRewards=[];
      }  
  }
 
  close() {
    this.dialogRef.close()
  }

}
