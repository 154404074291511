<!-- <div class="back-strip strip-2 border-0 bg-white pt-lg-1">
  <div class="container py-3 pt-lg-2 font-secondary">
    <button class="btn btn-outline-dark rounded-pill py-2 px-3" (click)="back()"><img
      src="assets/icons/back_arrow_icon.svg" class="me-lg-2 white-icon" alt=""><span
      class="align-middle d-none d-lg-inline">BACK</span></button>
    <h2
      class="d-inline-block d-lg-block mt-lg-1 mb-0 mb-lg-4 pb-lg-2 page-heading text-center align-middle"
    >
      My Profile
    </h2>
    <div class="qr-code-container"></div>
  </div>
</div> -->
<!-- <div class="position-sticky back-strip bg-white">
  <div class="container py-3 font-secondary">
    <button
      class="btn btn-outline-dark rounded-pill py-2 px-3"
      (click)="back()"
    >
      <img
        src="assets/icons/back_arrow_icon.svg"
        class="me-lg-2 white-icon"
        alt=""
      />
      <span class="align-middle d-none d-lg-inline">BACK</span>
    </button>
    <p
      class="d-inline-block ms-lg-4 mb-0 fs-5 page-heading text-center align-middle"
    >
      My profile
    </p>
  </div>
</div> -->

<div class="back-strip font-secondary bg-white firstDiv">
  <div class="container py-2 py-lg-3 d-flex" style="align-items: center;">        
              <button class="btn btn-outline-dark rounded-pill py-2 px-3" (click)="back()"><img
                 src="assets/icons/back_arrow_icon.svg" class="me-lg-2 white-icon" alt=""><span
                 class="align-middle d-none d-lg-inline">BACK</span></button>
              <p class="d-inline-block ms-lg-5 mb-0 fs-5 page-heading text-center align-middle"> My profile</p>
  </div>
</div>

<div class="container py-2">
  <!-- <div class="qr-container">
    <qrcode
      [qrdata]="custQrInfo"
      [width]="100"
      cssClass="qr-code-container"
      [errorCorrectionLevel]="'M'"
      (click)="onShowQR()"
    ></qrcode>
  </div> -->
  <form action="javascript:void(0)" class="row" [formGroup]="customerForm">
    <div class="col-lg-6">
      <div class="mb-3 pb-1">
        <label for="first-name" class="form-label mb-1">First Name*</label>
        <input
          type="text"
          class="form-control shadow-none rounded-1"
          id="first-name"
          formControlName="firstName"
        />
        @if (customerForm.controls['firstName'].hasError('required')) {
        <div class="form-field-error">Please enter first name.</div>
        }
        @if (customerForm.controls['firstName'].errors?.['pattern'] && customerForm.controls['firstName'].touched) {
          <div class="form-field-error">First Name contain only letters, hyphens, or apostrophes</div>
          }
      </div>
    </div>
    <div class="col-lg-6">
      <div class="mb-3 pb-1">
        <label for="last-name" class="form-label mb-1">Last Name</label>
        <input
          type="text"
          class="form-control shadow-none rounded-1"
          id="last-name"
          formControlName="lastName"
        />
        @if (customerForm.controls['lastName'].errors?.['pattern'] && customerForm.controls['lastName'].touched) {
          <div class="form-field-error">Last Name contain only letters, hyphens, or apostrophes</div>
          }
      </div>
    </div>
    <div class="col-md-6">
      <div class="mb-3 pb-1 d-flex flex-column">
        <label for="email" class="form-label">Email Address*</label>
        <div class="d-flex align-items-center gap-2">
          <input
            type="email"
            class="form-control shadow-none rounded-1"
            id="email"
            formControlName="email"
            [readonly]="!isMailEditMode"
          />

          
          <div class="button-container">
            <button
              class="btn btn-primary rounded-pill py-2 px-4 font-secondary"
              style="background-color: black; color: white"
              *ngIf="!isMailEditMode"
              (click)="onEmailChange()"
            >
              Change
            </button>

            <div *ngIf="isMailEditMode" class="actions-container">
              <button
                class="btn btn-primary rounded-pill py-2 px-4 font-secondary "
                style="background-color: black; color: white"
                (click)="onVerifyEmail()"
              >
                Verify
              </button>
              <button
                class="btn btn-primary rounded-pill py-2 px-4 font-secondary "
                style="background-color: rgb(208, 10, 10); color: white"
                (click)="onCancelEmailChange()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        @if(emailVerifyMsg!==""){ @if(emailVerified==true){
        <span class="form-field-sucess">{{ emailVerifyMsg }}</span>
        } @else{
        <span class="form-field-error">{{ emailVerifyMsg }}</span>
        } } @if (customerForm.controls['email'].hasError('required')) {
        <div class="form-field-error">Please enter email.</div>
        } @if (customerForm.controls['email'].hasError('email') && customerForm.controls['email'].errors?.['pattern'] && customerForm.controls['email'].touched) {
        <div class="form-field-error">Please enter valid email.</div>
        }
      </div>
    </div>

    <div class="col-md-6">
      <div class="mb-3 pb-1">
        <label for="dob" class="form-label mb-1">DOB</label>
        <div style="display: flex; flex-direction: row" id="dateOfBirth" class="date-picker py-1">
          <mat-select
          name="day"
          id="day"
          style="width: 30%; margin-right: 5px; text-align: center;height: 45px;
          outline: none;
          font-size: 16px;
          border-radius: 5px;
          padding-left: 15px;
          padding-top: 6px;
          border: 1px solid #ccc;
          border-bottom-width: 2px;
          transition: all 0.3s e"
          panelClass="rounded"
          placeholder="DD"
          formControlName="dobDay"
         
            
          >
            @for(day of days; track $index) {
            <mat-option [value]="day.value">{{ day.label }}</mat-option>
            }
          </mat-select>

          <mat-select
            name="month"
            id="month"
            style="width: 30%; margin-right: 5px; text-align: center;height: 45px;
            outline: none;
            font-size: 16px;
            border-radius: 5px;
            padding-left: 15px;
            padding-top: 6px;
            border: 1px solid #ccc;
            border-bottom-width: 2px;
            transition: all 0.3s e"
            panelClass="rounded"
            placeholder="MMM"
            formControlName="dobMonth"
            
            
          >
            @for(month of months; track $index) {
            <mat-option [value]="month.value">{{ month.label }}</mat-option>
            }
          </mat-select>
          
          <mat-select
            name="year"
            id="year"
            style="width:30%; margin-right: 5px; text-align: center;
            height: 45px;
            outline: none;
            font-size: 16px;
            border-radius: 5px;
            padding-left: 15px;
            padding-top: 6px;
            border: 1px solid #ccc;
            border-bottom-width: 2px;
            transition: all 0.3s e"
            panelClass="rounded"
            placeholder="YYYY"
            formControlName="dobYear"
          >
            @for (year of pastYears; track $index) {
            <mat-option [value]="year.value">{{ year.label }}</mat-option>
            }
          </mat-select>
        </div>

        <!-- @if (!customerForm.get('dobDay')?.value || !customerForm.get('dobMonth')?.value || !customerForm.get('dobYear')?.value) {
          <span class="text-danger mb-1">Fill all DOB fields or leave all empty.</span>
        }  -->
      </div>
      <!-- @if ("customerForm.get('dobDay')?.value && (customerForm.get('dobMonth')?.value || customerForm.get('dobYear')?.value)) || (!customerForm.get('dobDay')?.value && (customerForm.get('dobMonth')?.value || customerForm.get('dobYear')?.value)) || (!customerForm.get('dobMonth')?.value && (customerForm.get('dobDay')?.value || customerForm.get('dobYear')?.value)") {
        <span class="text-danger mb-1">Fill all DOB fields or leave all empty.</span>
        }  -->
       
        
      
    </div>
    <div class="col-md-6">
      <div class="mb-3 pb-1">
        <label for="phone-number" class="form-label mb-1">Phone Number</label>
        <input
          type="tel"
          class="form-control shadow-none rounded-1"
          id="phone-number"
          formControlName="phoneNumber"
          maxlength="13"
          pattern="[0-9]*" (keypress)="preventNonNumeric($event)"
        />
        @if (customerForm.controls['phoneNumber'].hasError('required')) {
          <div class="form-field-error">Please enter phone number.</div>
          } @if (customerForm.controls['phoneNumber'].hasError('minlength')) {
          <div class="form-field-error">Please enter valid phone number.</div>
          }
      </div>
      
    </div>
    <div class="col-lg-6">
      <div class="mb-3 pb-1 d-flex flex-column">
        <label for="password" class="form-label mb-1">Password</label>
        <div class="position-relative d-flex align-items-center gap-2">
          <input
            [type]="pwHide ? 'password' : 'text'"
            class="form-control shadow-none rounded-1"
            id="password"
            value="0123456"
            readonly
          />
         
          <button
            class="btn btn-primary rounded-pill py-2 px-4 font-secondary "
            style="background-color: black; color: white;"
            (click)="passwordDialog()"
          >
            Change
          </button>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="mb-3 pb-1">
        <label for="address" class="form-label mb-1">Address</label>
        <input
          type="text"
          class="form-control shadow-none rounded-1"
          id="address"
          formControlName="address"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="mb-3 pb-1">
        <label for="city" class="form-label mb-1">City</label>
        <input
          type="text"
          class="form-control shadow-none rounded-1"
          id="lastName"
          formControlName="city"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="mb-3 pb-1">
        <label for="state" class="form-label mb-1">State</label>
        <input
          type="text"
          class="form-control shadow-none rounded-1"
          id="state"
          formControlName="state"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="mb-3 pb-1">
        <label for="country" class="form-label mb-1">Country</label>
        <input
          type="text"
          class="form-control shadow-none rounded-1"
          id="country"
          formControlName="country"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="mb-3 pb-1">
        <label for="zip" class="form-label mb-1">Zip Code</label>
        <input
          type="text"
          class="form-control shadow-none rounded-1"
          id="postCode"
          formControlName="postCode"
        />
      </div>
    </div>
    <div class="col-12">
      <div class="form-check mb-1 pb-1">
        <input
          role="button"
          class="form-check-input shadow-none p-2 mt-1"
          type="checkbox"
          id="subscription"
          formControlName="subscription"
        />
        <label role="button" class="form-check-label align-middle" for="news">
          Please send me information on discounts, promotion and other news.
        </label>
      </div>
    </div>
  </form>
  
  @if(UpdateStatus==0){
    <button
    class="btn btn-primary rounded-pill py-2 px-4 font-secondary mt-2"
    (click)="onUpdate()"
  >
    Update
  </button>
    
  }
  @if(UpdateStatus==1){
    <button
    class="btn btn-primary rounded-pill py-2 px-4 font-secondary mt-2 text-dark"
    disabled
  >
  Updating&nbsp;<span class="spinner-border text-dark" style="height: 18px;width: 18px;"></span>
  </button>
    
  }

  

</div>
<!-- @if (!customerForm.get('dobDay')?.value && !customerForm.get('dobMonth')?.value && !customerForm.get('dobYear')?.value) {
  // All fields are empty, so don't display the message
} else if (customerForm.get('dobDay')?.value || customerForm.get('dobMonth')?.value || customerForm.get('dobYear')?.value) {
  // At least one field is filled, so display the message
  <span class="text-danger mb-1">Fill all DOB fields or leave all empty.</span>
} -->