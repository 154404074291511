<!-- @if (showScrollButton) {
    <div class="btn-scroll">
        @if (scrollToBottmVisible) {
            <div  class="btn-scroll-image-down"  (click)="scrolToBottom()" style="background: url(assets/images/icons/scroll-down-double-sprite-y.png);"></div>
        }
        @else {
        <div class="btn-scroll-image-up"  (click)="scrolToTop()" style="background: url(assets/images/icons/scroll-down-double-sprite-y.png);"></div>
        }
        
    </div>
} -->

<div id="receipt-viewer">
  <div class="receipt-heading">
    <img src="assets/images/payment-sucees.png" class="payement-succes-icon">
    <span class="receipt-heading">Thank you. </span>
    <span class="receipt-heading">Your order has been received.</span>
  </div>

  <div class="view-receipt-notice container">

    <span class="receipt-sub-heading">Please show this receipt when you pickup your order.</span>
    <span class="receipt-content">Downloading the receipt automatically. If download does not start,
      <span style="cursor:pointer;
        color:red;
        text-decoration:underline;" (click)="clickHereToDownload()">Click here</span> to download.</span>
  </div>
  <div class="download-receipt" (click)="downloadReceipt()" style="text-align: center;padding-top:10px ;"> <span
      class="fa fa-download" style="color: aliceblue;font-size: 20px;"></span></div>

  <div class="information-div">

    <div class="receipt-detail">
      <!-- <div class="close-button" (click)="outerReceiptClick()">
        <img class="image-close-btn"
          src="assets/close-button.png" /> 
        </div> -->

      <div class="receipt-div-detail">
        @if (loading) {
        <div class="confirmation-loading-div">
          <img class="confirmation-loading-panel" id="confirmation-loading-id" src="assets/Loading_icon.gif">
        </div>
        }
        @if (!loading) {
        <div id='receiptContentDiv' class="receiptContentDiv" [innerHTML]="htmlData"></div>
        }
      </div>



    </div>
  </div>
</div>