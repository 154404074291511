
import { Component, Inject, NgModule, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { LoginComponent } from "../login/login.component";
import {
  FormGroup,
  FormControl,
  Validators,
  ReactiveFormsModule,
} from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { CustomerService } from "../../services/customer/customer.service";
import { MessageDialogComponent } from "../my-account/message-dialog/message-dialog.component";
import { RouterModule } from "@angular/router";
// import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, ReCaptchaV3Service, } from "ng-recaptcha";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaModule, ReCaptchaV3Service } from "ng-recaptcha-2";
import { environment } from "../../environments/environment";
import { RegistratioSuccesMessageDialogComponent } from "../signup/registratio-succes-message-dialog/registratio-succes-message-dialog.component";
import { SignupComponent } from "../signup/signup.component";
import { Location } from '@angular/common'
import { TermsConditionsPopupComponent } from "../terms-conditions/terms-conditions-popup.component";


@NgModule({
  imports: [
    RecaptchaModule
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.oos.siteKey
    },ReCaptchaV3Service
  ]
})
export class CustomerRegistrationComponentModule { }
@Component({
    selector: 'app-customer-registration',
    
    imports: [CommonModule,
        ReactiveFormsModule,
        MatSelectModule,
        FormsModule,
        MatInputModule,
        MatFormFieldModule,
        RecaptchaModule,
        RouterModule,
        CustomerRegistrationComponentModule],
    templateUrl: './customer-registration.component.html',
    styleUrl: './customer-registration.component.scss'
})
export class CustomerRegistrationComponent implements OnInit {
  registrationStatus:number=0;
  pwHide: any = true;
  cwHide: any = true;
  customerDtails: any;
  days: any = [];
  months: any = [];
  pastYears: any = [];
  singUpForm: FormGroup<any>;
  confirmPassError = "";
  recaptchaToken: string = "";
  reCaptchaAction: string = "signup";

  constructor(
    private location: Location,
    public dialog: MatDialog,
    private customerService: CustomerService,
    private recaptchaV3Service: ReCaptchaV3Service,
    @Inject(RECAPTCHA_V3_SITE_KEY) private siteKey: string
  ) {
    
    const currentYear = new Date().getFullYear();

    this.pastYears.push({ value: "0004", label: "" });
    for (let i = 1; i <= 100; i++) { // Start from 1 to exclude current year
      const year = currentYear - i;
      this.pastYears.push({ value: year, label: year.toString() });
    }


    const currentDate = new Date();
    this.months = [
      { value: 0, label: "" },
      ...Array.from({ length: 12 }, (_, i) => ({
        value: i + 1,
        label: new Date(currentYear, i).toLocaleDateString("en-US", {
          month: "long",
        }),
      })),
    ];

    this.days = [
      { value: 0, label: "" },
      ...Array.from({ length: 31 }, (_, i) => ({
        value: i + 1,
        label: (i + 1).toString(),
      })),
    ];

    const strongPasswordRegx: RegExp = /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;
    this.singUpForm = new FormGroup({
      firstName: new FormControl("", Validators.required),
      lastName: new FormControl(""),
      email: new FormControl("", [Validators.required, Validators.email]),
      dob: new FormControl(""),
      dobDay: new FormControl(""),
      dobMonth: new FormControl(""),
      dobYear: new FormControl(""),


      newPassword: new FormControl("", [Validators.required, Validators.pattern(strongPasswordRegx)]),
      confirmPassword: new FormControl("", [Validators.required, Validators.pattern(strongPasswordRegx)]),
      phoneNumber: new FormControl("", [
        Validators.required,
        Validators.maxLength(13),
        Validators.minLength(9),
      ]),

      subscription: new FormControl(""),
      agree: new FormControl(""),

    });
  }
  
  get passwordFormField() {
    return this.singUpForm.get('newPassword');
  }
  ngOnInit() {

    // this.recaptchaV3Service.execute(this.reCaptchaAction)
    //   .subscribe(token => {
    //     this.recaptchaToken = token;
    //   });
  }

  onPasswordChange(passType: string): void {
    this.passwordMatchCheck();
  }
  /**
   * 
   * to check the new password and confirmpassword are same 
   */
  passwordMatchCheck(): boolean {
    this.confirmPassError = "";
    const confirmPass = this.singUpForm.controls["confirmPassword"].value;
    const newPass = this.singUpForm.controls["newPassword"].value;
    if (confirmPass !== "" && confirmPass !== newPass) {
      this.confirmPassError =
        "New password and confirmation password don't match.";
      return false;
    }
    return true;
  }

  
  loginDialog() {
    this.dialog.open(LoginComponent, {
      panelClass: "login-dialog",
    });
   
  }
   /**
    * to set the detilas of a new singup customer 
    */
   signUp(): void {
    if (this.singUpForm && this.singUpForm.get("agree")) {
      const customerDetails = {
        title: "",
        first_name: this.singUpForm.controls["firstName"].value,
        last_name: this.singUpForm.controls["lastName"].value,
        email: this.singUpForm.controls["email"].value,
        login_id: this.singUpForm.controls["email"].value,
        address: "",
        city: "",
        state: "",
        country: "",
        post_code: "",
        dob: "",
        phone_number: this.singUpForm.controls["phoneNumber"].value,
        gender: "",
        password: this.singUpForm.controls["newPassword"].value,
        is_subscribed: 1,
        is_valid: 1,
      };
      const agreeChecked = this.singUpForm.get("agree")!.value;
     

      if (this.singUpForm.valid && agreeChecked) {
        this.registrationStatus=1;
        this.recaptchaV3Service.execute(this.reCaptchaAction)
        .subscribe(token => {
          this.recaptchaToken = token;
          this.customerService.singUpCustomer({...customerDetails,gToken:this.recaptchaToken}).subscribe({
            next: (response) => {
              
              if (response.status=="SUCCESS") {
                this.registrationStatus=0;
                const reward = response.data.reward;
                this.succesDialog(reward);
               
                
              }else{
                const message = response.message;
                this.showDialog(message);
                this.registrationStatus=0;
              }
            },
            error: (error) => {
              const message = error.message;
              this.showDialog( "Failed to register customer. Please check the details and try again.");
            },
          });
        });
       
      } else {
        if (!agreeChecked) {
          this.showDialog("Please agree to the Terms and Privacy Policy.");
        }
      }
    } else {
      console.error("Form or form control is null.");
    }
  }
  
  /**
   * 
   * to show the response message 
   */
  showDialog(message: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { title: "SIGN UP", content: message };
    dialogConfig.panelClass = "app-message-dialog";
    const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);
  }
  /**
   * 
   * to show the success message 
   */
  succesDialog(reward: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { title: "Welcome to St. Pierres Sushi!",  reward: reward };
    dialogConfig.panelClass = "app-registratio-succes-message-dialog";
    const dialogRef = this.dialog.open(RegistratioSuccesMessageDialogComponent, dialogConfig);
  }

  back() {
    this.location.back()
   
  }

  termsConditionsDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = "app-terms-conditions-popup";
    const dialogRef = this.dialog.open(TermsConditionsPopupComponent, dialogConfig);
  }

  
}
