import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { MatModule } from '../../modules/mat.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { UtilService } from '../../services/shared/util.service';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageService } from '../../services/storage/storage.service';
import { HomeService } from '../home/home.service';
import { SESSION_COMPANY_INFO, SESSION_DEPARTMENT_LIST } from '../../app.constants';

@Component({
    selector: 'app-faq',
    
    imports: [MatModule, MatExpansionModule],
    templateUrl: './faq.component.html',
    styleUrl: './faq.component.scss'
})
export class FaqComponent {
  showHeader:boolean=true;
  companyInfo: any;
  faqContent: any;

  
  constructor(private location: Location,
    private utilService: UtilService,
    private sanitizer: DomSanitizer,
    private storageService: StorageService,
    private homeService: HomeService,
  ) { 

  }
  ngOnInit(){
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.setCompanyInfo();
  }
  back() {
    this.location.back()
  }
   

  // initTitleBarParams() {
  //   this.utilService.fwConfigService.showFooterBar = false;
  //   this.utilService.resetFooterConfigData(this);
  //   const titleConfig: TitleConfig = {
  //     title: 'TERMS AND CONDITIONS',
  //     showLogo: false,
  //     showShoppingCart: false,
  //     hideHamBurgerMenu: false,
  //     hideBackButton: false
  //   };
  //   this.utilService.setTitleBarParams(this, titleConfig);
  //   const footerConfig: FooterConfig = {
  //   };
  //   this.utilService.setFooterBarParams(this, footerConfig);
  // }
  setCompanyInfo(){
    this.homeService.getCompanyInfo().subscribe((companydata: any) => {
      this.companyInfo = companydata.object[0];
      this.storageService.saveToSession(SESSION_COMPANY_INFO,JSON.stringify(this.companyInfo));
    this.faqContent = this.sanitizer.bypassSecurityTrustHtml(this.companyInfo.faq);

    });
  }
}
