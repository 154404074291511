import { Component, ElementRef, ViewChild  } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OOModule } from '../../modules/oo.module';
import { StorageService } from '../../services/storage/storage.service';
import { SESSION_CUSTOMER_ACTIVE_REWARDS, SESSION_APPLIED_REWARDS, SESSION_CUSTOMER_REWARDS } from '../../app.constants';
import { environment } from '../../environments/environment';
import { NotificationService } from '../../services/shared/notifications.service';
import { MyRewardCardComponent } from './my-reward-card/my-reward-card.component';
import { RewardCardComponent } from '../rewards/components/reward-card/reward-card.component';
import { CommonModule, DatePipe } from '@angular/common';
import { Location } from '@angular/common'



@Component({
    selector: 'app-my-rewards',
    imports: [OOModule, MyRewardCardComponent, CommonModule],
    templateUrl: './my-rewards.component.html',
    styleUrl: './my-rewards.component.scss'
})
export class MyRewardsComponent {

  rewardsData: any;
  appliedRewards:any=[];

  @ViewChild('rewardImg')
  rewardImgRef!: ElementRef<HTMLImageElement>; // Reference to the image


  constructor(
    private location: Location,
    private storageService:StorageService,private datePipe: DatePipe) { }

  ngOnInit() {
      this.rewardsData = JSON.parse(this.storageService.loadFromSession(SESSION_CUSTOMER_REWARDS)); 
      this.appliedRewards = JSON.parse(this.storageService.loadFromSession(SESSION_APPLIED_REWARDS));  
      if(this.appliedRewards==undefined){
        this.appliedRewards=[];
      }  
      for (const reward of this.rewardsData) {
        this.getRewardStatus( reward.customer_valid_until); // Pass customer_valid_until if available
        
    }
  }
  
 

   hardcodedValidUntilDate = new Date('2025-01-04'); // Replace with your desired future date

   getRewardStatus(reward: any): { text: string; color: string; description?: string } {
    var status=reward.customer_status;
    var rewardValidUntil= new Date(reward.valid_until);
    var customerValidUntil = new Date(reward.customer_valid_until);
    
  
    
    var validUntil =  rewardValidUntil.getTime() < customerValidUntil.getTime() ?  rewardValidUntil : customerValidUntil;
  
    switch (status) {
      case 1:
        const today = new Date();
        const diffInDays = Math.floor((validUntil.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
  
        if (diffInDays > 30) {
          return { text: 'Active', color: 'green', description: 'Use this reward before ' + this.datePipe.transform(validUntil, 'mediumDate') };
        } else if (diffInDays > 0) {
          return { text: 'Active ', color: 'green', description: 'Will be expired within ' + diffInDays + ' days' };
        } else {
          return { text: 'Expired', color: 'red', description: 'The reward has been expired on ' + this.datePipe.transform(validUntil, 'mediumDate') };
        }
      case 2:
        return { text: 'Expired', color: 'red', description: 'The reward has been expired on ' + this.datePipe.transform(validUntil, 'mediumDate') };
        case 3:
          try {
            // Parse the JSON string and access the discount_amount
            const orderRefData = JSON.parse(reward.customer_order_ref);
            const discountAmount = orderRefData.discount_amount;
      
            return {
              text: 'Used',
              color: 'orange',
              description: `You have saved $${discountAmount} with this reward.`
            };
          } catch (error) {
            console.error('Error parsing JSON:', error);
            // Handle parsing error (optional: return a default description)
            return { text: 'Used', color: 'orange', description: 'You have used this reward.' };
          }
      default:
        return { text: 'Unknown', color: 'black', description: 'You have used this reward.' };
    }

    
  }
  

back() {
  this.location.back()
 
}





}
