import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { StorageService } from '../../../../services/storage/storage.service';
import { RewardService } from '../../../../services/shared/reward.service';
import { CartService } from '../../../../services/cart/cart.service';
import { MessageDialogService, NotificationDialogModel } from '../../../../services/shared/message-dialog.service';
import { CUSTOMER_TYPE_GUEST, SESSION_APPLIED_REWARDS, SESSION_CUSTOMER_REWARDS } from '../../../../app.constants';
import { NotificationService } from '../../../../services/shared/notifications.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OfferMessageDialogComponent } from './offer-message-dialog/offer-message-dialog.component';
import { PromotionValidityCheckService } from '../../../../services/shared/promotion-validity-check.service';
import { ExternalOfferComponent } from '../../../external-offer/external-offer.component';

@Component({
    selector: 'app-promotion-card',
    imports: [],
    templateUrl: './promotion-card.component.html',
    styleUrl: './promotion-card.component.scss'
})
export class PromotionCardComponent {

  @Input() promotion: any
  isError: boolean = false;
  crmAssetsUrl = environment.crm.assetsUrl;
  promoItems!: { buy: any[], promotion: any[] };
  appliedRewards: any = [];
  isExternalPromotion: boolean = false;

  constructor(private storageService: StorageService,
    private rewardService: RewardService,
    private messageDialogService: MessageDialogService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private cartService: CartService,
    private promotionValidityCheckService: PromotionValidityCheckService
  ) { }

  ngOnInit() {

  }

  @ViewChild('promotionImage')
  promotionImage!: ElementRef<HTMLImageElement>; // Reference to the image

  ngAfterViewInit() {
    const image = this.promotionImage.nativeElement;
    image.onerror = () => {
      this.isError = true;
    };
  }

  /**
   * Shows the popup for selecting promotion
   * @param promotion 
   */
  showPromotionSelection(promotion: any): void {
    var isApplied = false;
    const appliedRewards = this.rewardService.getAppliedRewards();
    console.log(appliedRewards)
    appliedRewards.forEach((cr: any) => {
      if (cr.customer_reward_id == this.promotion.customer_reward_id) {
        isApplied = true;
      }
      else {
        isApplied = false;
      }
    });
    if (isApplied) {
      var dlgData: NotificationDialogModel = {
        message: `This reward has been added to the cart and requires a minimum order of $`+promotion.discount.buy.trigger_on.amount+`. You can use this reward only once.`,
        title: "Reward Already Added",
        yesButtonCaption: "Ok",
      }
      this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => {
      });
    }
    else {
      if (promotion.is_external == 1 && promotion.external_url !== "" && promotion.external_url !== null && promotion.external_url.includes('|')) {

        this.externalLinkOpen(promotion);
      }
      if (promotion.is_external !== 1 && this.promotionValidityCheckService.canUse(promotion)) {
        this.rewardService.showPromotionSelection(promotion);
      } 
    }
  }

  /**
   * For external link promotion
   */
  externalLinkOpen(promotion: any) {
    const externalOffer = this.externalOffer(promotion.external_url);
    if(externalOffer !== undefined) {
      const dlgData={externalPromotion:promotion,offerContent:externalOffer}
      switch (externalOffer.type) {
        case "html":
          this.dialog.open(ExternalOfferComponent, {
            panelClass: 'app-message-dialog',data: dlgData
          });
          break;
          case "url":
            window.open(externalOffer.content, '_blank');
          break;
        default:
          break;
      }
    }
    // window.open(url, '_blank');
  }

  externalOffer(value: string): { type: string; content: string }|undefined {
    const pipeIndex = value.indexOf('|');
    if (pipeIndex !== -1) {
      const beforeContent = value.substring(0, pipeIndex);
      const afterContent = value.substring(pipeIndex + 1);
      return { type: beforeContent, content: afterContent };
    } else {
      return undefined;
    }
  }

  /**
   * Check promotion validity.
   * @param promo 
   * @returns 
   */
  checkForPromotionValidity(promo: any) {
    var custRewards = this.rewardService.getCustomerRewards();
    const isValid = custRewards.some((cr: any) => cr.id = promo.id);
    return isValid;
  }

  /**
   * Check for the offer/reward for avialability
   * @returns 
   */
  canUse(): boolean {
    var isValid = true;
    const dialogConfig = new MatDialogConfig();
    // Check for reward
    if (this.promotion.reward_eligibility == 2) {
      isValid = this.checkForPromotionValidity(this.promotion);
      if (!isValid) {
        var message = "This  Offer is reserved exclusively for registered customer. Please register as a customer.";
        switch (this.promotion.crm_event_id) {
          case 1:
            message = "This Welcome Offer is reserved exclusively for newly registered customer. Please register as a customer.";
            break;
          case 2:
            message = "This Birthday Offer is reserved exclusively for registered customer. Please register as a customer.";
            break;
          case 3:
            message = "This Anniversary Offer is reserved exclusively for registered customer. Please register as a customer.";
            break;
          default:
            message = "This  Offer is reserved exclusively for registered customer. Please register as a customer.";
            break;
        }

        dialogConfig.data = { message: message, title: "REGISTER TO GET THIS OFFER!", yesButtonCaption: "Ok", signINButtonCaption: "SIGN UP" };
        dialogConfig.panelClass = "confirm-dialog";
        this.dialog.open(OfferMessageDialogComponent, dialogConfig);
      }
    }
    // Check for an offer
    else {
      isValid = true;
      if (this.promotion.enabled_for !== null) {
        if (this.cartService.isGuestLogin()) {
          if (!this.promotion.enabled_for.some((pr: any) => pr == CUSTOMER_TYPE_GUEST)) {
            isValid = false;
            message = "To available this offer, you must signed in first. Please sign in."
            dialogConfig.data = { message: message, title: "LOGIN TO GET THIS OFFER!", yesButtonCaption: "Cancel", signINButtonCaption: "SIGN IN" };
            dialogConfig.panelClass = "confirm-dialog";
            this.dialog.open(OfferMessageDialogComponent, dialogConfig);
          }
        }

      }
    }
    return isValid;
  }

}
